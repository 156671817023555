import request from 'axios';
import { observable, action, computed, runInAction } from 'mobx';
import UIStore from './UIStore';
import orderBy from 'lodash.orderby';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import axiosDownload from 'utils/axiosDownload';

import {
  MaterialForm,
  materialFormOptions,
  materialFormFields,
  materialFormRules,
  materialFormLabels,
  materialFormPlugins
} from 'forms/material';

export default class CompanyMaterialsUI extends UIStore {
  @observable entryForEdit;

  @observable uploadProgress;
  @observable mode;

  @observable searchQuery;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.companyProductionUI = options.companyProductionUI;

    // Selectin
    this.selectedMaterials = observable([]);

    // Uploading
    this.uploadProgress = 0;

    // Searching
    this.searchQuery = '';

    // Sorting
    this.sortDirection = 'asc';

    // Editing
    this.entryForEdit = null;

    // Pagination
    this.pageSize = 20;
    this.page = 0;

    // Instantiate the create material form
    this.materialForm = new MaterialForm(
      {
        fields: materialFormFields,
        rules: materialFormRules,
        labels: materialFormLabels
      },
      {
        options: materialFormOptions,
        plugins: materialFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.searchQuery = '';
    this.selectedMaterials.clear();
    this.rootStore.materials.clearNew();

    this.page = 0;
  }

  @computed
  get hasActiveModal() {
    return this.activeModal || this.unitsUI.activeModal;
  }

  @action.bound
  setMode(mode) {
    this.mode = mode;
  }

  @action.bound
  setDefaultMode() {
    this.mode = this.hasMaterials || this.disableFields ? 'manual' : 'upload';
  }

  @action.bound
  async fetchMaterials() {
    if (this.rootStore.companyMaterialsFetched) {
      this.setDefaultMode();
      return;
    }

    await this.rootStore.materials.fetch({
      params: {
        isDefault: true,
        limit: 10000
      },
      add: true,
      remove: false,
      update: true
    });

    this.rootStore.companyMaterialsFetched = true;
    this.setDefaultMode();
  }

  @computed get materials() {
    return this.rootStore.materials.models.filter(material => {
      return material.isDefault;
    });
  }

  @computed
  get hasMaterials() {
    return this.materials.length > 0;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @computed get sortedMaterials() {
    return orderBy(
      this.materials,
      [material => material.new, material => material.name.toLowerCase()],
      ['desc', this.sortDirection]
    );
  }

  @computed get searchedMaterials() {
    if (!this.searchQuery) {
      return this.sortedMaterials;
    }

    const query = this.searchQuery.toLowerCase();

    return this.sortedMaterials.filter(material => {
      return material.name.toLowerCase().indexOf(query) > -1;
    });
  }

  @computed get hasSearchedMaterials() {
    return this.searchedMaterials.length > 0;
  }

  @computed get paginatedMaterials() {
    return this.searchedMaterials.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @action.bound
  sortByColumn() {
    // Clear New Item at the top of list on sort.
    this.rootStore.materials.clearNew();

    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedMaterials.length / this.pageSize);
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
  }

  @computed
  get materialFormIsInValid() {
    if (
      !this.materialForm ||
      (this.materialForm.existingMaterial &&
        this.materialForm.existingMaterial.isDefault)
    )
      return true;

    return Boolean(
      this.materialForm.check('hasError') ||
        this.materialForm.$('name').check('isPristine') ||
        this.materialForm.$('unit').check('isPristine')
    );
  }

  @action.bound async saveNewMaterial(values) {
    if (this.materialForm.existingMaterial) {
      this.materialForm.existingMaterial
        .save({
          isDefault: true
        })
        .then(model => {
          this.showMaterialCreated(model);
        })
        .catch(error => {
          errorHandler(error, this.notifications.pushError);
        });
    } else {
      this.rootStore.materials
        .create({
          name: values.name,
          unit: this.rootStore.units.get(values.unit).formValues,
          isDefault: true
        })
        .then(material => {
          runInAction(() => {
            this.showMaterialCreated(material);
          });
        })
        .catch(error => {
          errorHandler(error, this.notifications.pushError);
        });
    }
  }

  @action.bound
  showMaterialCreated(material) {
    material.setAsNew();

    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: t('Material Created')
    });

    this.setPage({
      selected: 0
    });
  }

  @computed get hasSelectedMaterials() {
    return this.selectedMaterials.length > 0;
  }

  @action.bound
  toggleSelectMaterial(material) {
    if (this.selectedMaterials.find(id => id === material.id)) {
      this.selectedMaterials.remove(material.id);
    } else {
      this.selectedMaterials.push(material.id);
    }
  }

  @computed
  get allMaterialsSelected() {
    return (
      this.hasMaterials &&
      this.selectedMaterials.length === this.materials.length
    );
  }

  @action.bound
  toggleSelectAllMaterials() {
    if (this.allMaterialsSelected) {
      this.selectedMaterials.clear();
    } else {
      this.selectedMaterials.replace(
        this.searchedMaterials.map(material => material.id)
      );
    }
  }

  @action.bound async softDeleteMaterial(material) {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    const originalIndex = material.collection.models.indexOf(material);

    this.cancelEntryEdit();

    material.collection.remove(material);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteMaterial(material, originalIndex);
      },
      onDismiss: () => {
        this.confirmDeleteMaterial(material);
      },
      title: t('Material Deleted')
    });
  }

  @action.bound
  cancelDeleteMaterial(material, index) {
    this.rootStore.materials.add(material, {
      at: index
    });
  }

  @action.bound
  confirmDeleteMaterial(material) {
    material.destroy();
  }

  @action.bound
  deleteSelectedMaterials() {
    this.cancelEntryEdit();

    const materials = this.materials.filter(material =>
      this.selectedMaterials.includes(material.id)
    );

    this.softDeleteMaterials(materials);
    this.selectedMaterials.clear();
  }

  @action.bound async softDeleteMaterials(materials) {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    this.rootStore.materials.remove(materials);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteMaterials(materials);
      },
      onDismiss: () => {
        this.confirmDeleteMaterials(materials);
      },
      title: t('Materials Deleted')
    });
  }

  @action.bound
  cancelDeleteMaterials(materials) {
    this.rootStore.materials.add(materials);
  }

  @action.bound
  confirmDeleteMaterials(materials) {
    materials.forEach(material => {
      material.destroy();
    });
  }

  @action.bound
  upload(files) {
    const formData = new FormData();
    formData.append('file', files[0]);

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        this.setUploadProgress(percentCompleted);
      },
      transformRequest: [
        function(data, headers) {
          delete headers.post['content-type'];
          return data;
        }
      ]
    };

    return new Promise((resolve, reject) => {
      request
        .post(
          `${this.urlMicroService('performanceTracking')}/materials/import`,
          formData,
          config
        )
        .then(
          response => {
            runInAction(() => {
              this.rootStore.materials.set(response.data.collection, {
                add: true,
                remove: false,
                update: true
              });

              this.clearUploadProgress();
              this.setMode('manual');

              // Refetch the units list
              this.rootStore.units.fetch({
                params: {
                  limit: 10000,
                  mn: 'full',
                  mc: 'full'
                }
              });

              this.notifications.pushNotification({
                showUndo: false,
                title: t('Materials successfully updated')
              });
            });

            resolve(response.data);
          },
          error => {
            this.clearUploadProgress();
            errorHandler(error, this.notifications.pushError);
          }
        );
    });
  }

  @action.bound
  setUploadProgress(progress) {
    this.uploadProgress = progress;
  }

  @action.bound
  clearUploadProgress() {
    this.uploadProgress = 0;
  }

  @action.bound
  uploadRejected() {
    this.notifications.pushNotification({
      showUndo: false,
      title: 'Please upload a valid .CSV file.'
    });
  }

  @action.bound async setEntryForEdit(material) {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    this.entryForEdit = material;

    this.entryEditForm = new MaterialForm(
      {
        fields: materialFormFields,
        rules: materialFormRules,
        labels: materialFormLabels,
        values: material.formValues
      },
      {
        options: materialFormOptions,
        plugins: materialFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound cancelEntryEdit() {
    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  checkIfEntryDisabled(entry) {
    return this.entryForEdit && this.entryForEdit.id !== entry.id;
  }

  @action.bound async submitEntryEditForm() {
    await this.authorization.checkFeatureAccess('CUDMaterials');

    if (
      this.entryEditForm.existingMaterial &&
      this.entryEditForm.existingMaterial.id !== this.entryForEdit.id
    )
      return;

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound submitEntryEditFormSuccess() {
    const { name, unit } = this.entryEditForm.trimmedValues();

    this.entryForEdit.save({
      name,
      unit: this.rootStore.units.get(unit)
        ? this.rootStore.units.get(unit).formValues
        : this.entryForEdit.unit
    });

    this.cancelEntryEdit();
  }

  @action.bound submitEntryEditFormError() {
    console.log(this.entryEditForm.errors());
  }

  @action.bound exportCSV() {
    axiosDownload(
      `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/materials/export/materials.csv`,
      t('Materials.csv')
    );
  }

  @computed get disableFields() {
    return (
      (this.company.disableLegacySettings &&
        !this.company.hideLegacySettings) ||
      !this.authorization.canCUDMaterials
    );
  }
}
