import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import Policies from 'stores/collections/time/Policies';

import history from 'utils/history';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import PolicyViewUI from './PolicyViewUI';
import PolicyAddUI from './PolicyAddUI';
import PolicyEditUI from './PolicyEditUI';
import PolicyDuplicateUI from './PolicyDuplicateUI';
import PayPeriodUI from './PayPeriodUI';
import ApprovalsUI from './ApprovalsUI';

import TimeClassificationsUI from './TimeClassificationsUI';

import AdvancedUI from './AdvancedUI';

export default class TimeUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedPolicy;
  @observable saving;

  constructor(options) {
    super(options);

    this.loading = true;
    this.saving = false;

    this.selectedPolicy = null;

    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Policies collection
    this.policies = new Policies(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.policyViewUI = new PolicyViewUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.policyAddUI = new PolicyAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.policyEditUI = new PolicyEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.advancedUI = new AdvancedUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.policyDuplicateUI = new PolicyDuplicateUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.timeClassificationsUI = new TimeClassificationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.payPeriodUI = new PayPeriodUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.approvalsUI = new ApprovalsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchPoliciesDebounced = debounce(this.fetchPolicies, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchPolicies();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchPoliciesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      status: ['ACTIVE']
    };
  }

  @action.bound async fetchPolicies() {
    this.policies.cancelRequest();
    this.policies.reset();

    try {
      await this.policies.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasPolicies() {
    return this.policies.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.policies.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.policies.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.selectedPolicy = null;
    this.clearValidationDetails();
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasPolicies;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasPolicies;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addPolicy() {
    await this.authorization.checkFeatureAccess('ReviewTime');

    history.push({
      pathname: `/company/time/policies/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editPolicy(policy) {
    await this.authorization.checkFeatureAccess('ReviewTime');
    history.push({
      pathname: `/company/time/policies/${policy.uuid}/settings`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deletePolicy(policy) {
    await this.authorization.checkFeatureAccess('ReviewTime');

    if (policy.default) {
      this.showModal('DefaultPolicyRequiredModal');
      return;
    }

    this.selectedPolicy = policy;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeletePolicy() {
    await this.hideActiveModal();

    this.selectedPolicy = null;
  }

  @action.bound async confirmDeletePolicy() {
    this.saving = true;

    try {
      await this.selectedPolicy.destroy({ wait: true });

      this.refetchPolicies();

      await this.hideActiveModal();

      this.selectedPolicy = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Policy deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchPoliciesDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchPoliciesDebounced();
  }

  @action.bound refetchPolicies() {
    this.loading = true;
    if (!this.hasPolicies) {
      this.setPage(null, 1);
      this.fetchPolicies();
    } else {
      this.fetchPolicies();
    }
  }

  getPolicyActions = policy => {
    return [
      {
        title: t('Edit'),
        onClick: () => this.editPolicy(policy)
      },
      {
        title: t('Duplicate'),
        onClick: () => this.policyDuplicateUI.showDuplicateModal(policy)
      },
      {
        title: t('Delete'),
        onClick: () => this.deletePolicy(policy)
      }
    ];
  };

  @computed get hasSetupDefaultPolicy() {
    return this.company.hasSetupDefaultPolicy;
  }

  @computed get policiesEnabled() {
    return this.company.policiesEnabled;
  }

  @action.bound policiesGetStarted() {
    this.showModal('reviewYourDefaultPolicyModal');
  }

  @action.bound hideReviewYourDefaultPolicyModal() {
    this.hideActiveModal();
  }

  @computed get defaultPolicy() {
    return this.policies.models.find(policy => policy.default);
  }

  @action.bound reviewNewDefaultPolicy() {
    this.editPolicy(this.defaultPolicy);
    this.hideReviewYourDefaultPolicyModal();
  }

  @action.bound showTurnOnPoliciesModal() {
    this.showModal('turnOnPoliciesModal');
  }

  @action.bound async turnOnPolicies() {
    this.saving = true;

    try {
      await this.company.save(
        { preferences: { timePolicyStatus: 'ENABLED' } },
        {
          wait: true,
          url: this.company.url() + '/preferences'
        }
      );

      this.hideActiveModal();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound managePolicies() {
    history.push({
      pathname: `/company/time/policies/manage`
    });
  }

  @action.bound backToPolicies() {
    history.push({
      pathname: `/company/time/policies`
    });
  }
}
