import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import Attachments from '../stores/collections/Attachments';
import { computed, action, reaction, when } from 'mobx';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';
import Attachment from 'stores/models/Attachment';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import { t } from 'utils/translate';
import { observable } from 'mobx';
import {
  TimeEntryForm,
  timeEntryFormRules,
  timeEntryFormFields,
  timeEntryFormOptions,
  timeEntryFormPlugins
} from './timeEntry';
import {
  TimeEntriesBreakForm,
  timeEntriesBreakFormFields,
  timeEntriesBreakFormOptions,
  timeEntriesBreakFormPlugins
} from './timeEntriesBreak';
import abbreviateNumber from 'utils/abbreviateNumber';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import orderBy from 'lodash.orderby';
import { callTrack } from 'utils/segmentIntegration';

import {
  TIMECARD_REALLOCATEHOURS,
  TIMECARD_REALLOCATEHOURS_ACCEPTED,
  TIMECARD_END_TIME_SUGGESTION_ACCEPTED,
  TIMECARD_END_TIME_SUGGESTION_OPENED
} from 'utils/segmentAnalytics/eventSpec';

const timeEntriesTimeCardFormPlugins = extendedPlugins;

const timeEntriesTimeCardFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const timeEntriesTimeCardFormFields = [
  'uuid',
  'note',
  'startTime',
  'endTime',
  'hoursWorked',
  'projectUuid'
];

const timeEntriesTimeCardFormRules = {
  note: 'string|max:500',
  hoursWorked: 'numeric|min:0|max:24'
};

class TimeEntriesTimeCardForm extends MobxReactForm {
  @observable addingPayrollNotes = false;
  @observable timeEntries = [];
  @observable breaks = [];
  @observable hideTimeEntriesTable;
  @observable breakAddedOrRemoved = false;
  @observable timeEntryAddedOrRemoved = false;
  @observable showHoursWorked;

  constructor(settings, options) {
    super(settings, options);

    // References
    this.rootStore = options.rootStore;
    this.worker = options.worker;

    this.currentTimeEntriesWorkLogDate = options.currentTimeEntriesWorkLogDate;
    this.timeEntriesWorkLogUI = options.timeEntriesWorkLogUI;

    if (options.attachments) {
      this.attachments = options.attachments;
    } else {
      // Otherwise create a new attachments collection
      this.attachments = new Attachments(null, {
        rootStore: options.rootStore
        // No parent sent here as there is no existing model
      });
    }

    this.timeCardDetails = settings.timeCardDetails;
    this.timeCardIsLocked = settings.isLockedForEditing;
    this.hasKioskActivity = this.timeCardDetails?.hasKioskActivity;
    this.hasTimeClockActivity = this.timeCardDetails?.hasTimeClockActivity;
    this.manualTimeEntry = settings.manualTimeEntry;
    this.hasSavedTime = Boolean(settings.values.startTime);

    this.initialTotalHours = settings.values.initialTotalHours;
    this.timeCardStatus = settings.timeCardStatus;
    this.isSynced = settings.isSynced;
    // settings.timeEntries.length === 0 -> a case of auto rollove
    this.hideTimeEntriesTable = this.isNew || settings.timeEntries.length === 0;
    this.bulkEditMode = settings.bulkEditMode;
    this.setTimeEntries(settings.timeEntries);
    this.setBreaks(settings.breaks);

    if (this.hideTimeEntriesTable && this.showStartEndTimeTable) {
      this.setupStartEndTimeReaction();
    }

    if (this.showStartEndTimeTable) {
      if (this.hasKioskActivity) {
        this.set('rules', {
          startTime: 'required'
        });
      } else {
        this.set('rules', {
          startTime: 'required',
          endTime: 'required'
        });
      }
    }

    // settings.timeEntries.length === 0 -> a case of auto rollover
    this.showHoursWorked =
      (this.isNew || settings.timeEntries.length === 0) &&
      !this.showStartEndTimeTable;
    this.savedTimeEntriesHoursAndPayTypesMap = this.getTimeEntriesHoursAndPayTypesMap();

    this.tearDownHoursWorkedReaction = reaction(
      () => this.hideTimeEntriesTable,
      hideTimeEntriesTable => {
        if (!this.showStartEndTimeTable) {
          this.showHoursWorked = hideTimeEntriesTable;
        } else {
          this.setupStartEndTimeReaction();
        }
      }
    );
  }

  @computed
  get defaultPayType() {
    return this.rootStore.payTypeSelectorUI.defaultOption;
  }

  @computed
  get isNew() {
    return !this.$('uuid').value;
  }

  @computed
  get isLockedForEditing() {
    return this.timeCardIsLocked || this.isOnTheClock;
  }

  @computed
  get isOnTheClock() {
    return this.fromKioskOrTimeClock && !this.$('endTime').value;
  }

  setupStartEndTimeReaction() {
    this.tearDownStartTimeReaction = reaction(
      () => this.$('startTime').value,
      startTime => {
        this.updateTimeEntryHours();
      }
    );

    this.tearDownEndTimeReaction = reaction(
      () => this.$('endTime').value,
      endTime => {
        this.updateTimeEntryHours();
      }
    );

    this.tearDownBreakReaction = reaction(
      () => this.unpaidBreaksInMinutes,
      unpaidBreaksInMinutes => {
        this.updateTimeEntryHours();
      }
    );
  }

  @action.bound
  updateTimeEntryHours() {
    if (!this.$('endTime').value || !this.$('startTime').value) {
      this.$('hoursWorked').set(0);
    } else {
      this.$('hoursWorked').set(this.paidHoursFromTimeFrame);
    }
  }

  @action.bound
  setTimeEntries(timeEntries) {
    if (timeEntries.length) {
      timeEntries.forEach(timeEntry => {
        this.addTimeEntry(timeEntry);
      });
    } else {
      this.addTimeEntry();
    }
  }

  @action.bound
  addHours() {
    if (!this.timeEntryAddedOrRemoved) {
      this.timeEntryAddedOrRemoved = true;
    }

    when(
      () => !this.worker.settings.fetchingWorkerDefaults,
      () => {
        this.addTimeEntry();
      }
    );
  }

  @action.bound
  addBulkHours() {
    if (!this.timeEntryAddedOrRemoved) {
      this.timeEntryAddedOrRemoved = true;
    }

    this.addTimeEntry();
  }

  @computed
  get defaultClassification() {
    //TODO: this can be removed when we tidy up FF_WORKER_DEFAULT_ATTRIBUTES
    if (!this.worker) return null;

    if (Boolean(this.worker.defaultClassification)) {
      return {
        name: this.worker.defaultClassification.name,
        uuid: this.worker.defaultClassification.uuid
      };
    }

    return null;
  }

  @computed get workerDefaultCostCode() {
    if (
      (this.hoursWorked || this.totalHours) &&
      this.worker?.settings.defaultCostCode?.getProjectByUuid(this.project.uuid)
    ) {
      return this.worker.defaultCostCode;
    }

    return null;
  }

  @action.bound
  addTimeEntry(timeEntry) {
    const timeEntryForm = new TimeEntryForm(
      {
        fields: timeEntryFormFields,
        rules: timeEntryFormRules,
        values: timeEntry
          ? timeEntry.formValues
          : {
              hours: 0,
              payType: this.defaultPayType,
              classification:
                this.worker?.defaultClassification ||
                this.defaultClassification,
              shift: this.worker?.defaultShift || null,
              costCode: this.workerDefaultCostCode
            }
      },
      {
        options: timeEntryFormOptions,
        plugins: timeEntryFormPlugins,
        order: isNaN(parseInt(timeEntry?.order))
          ? this.timeEntries.length + 1
          : timeEntry.order,
        rootStore: this.rootStore
      }
    );

    this.timeEntries.push(timeEntryForm);
  }

  @computed
  get orderedTimeEntries() {
    return orderBy(this.timeEntries, ['order'], ['asc']);
  }

  @action.bound
  removeTimeEntry(timeEntryForm) {
    if (!this.timeEntryAddedOrRemoved) {
      this.timeEntryAddedOrRemoved = true;
    }
    this.timeEntries.remove(timeEntryForm);
  }

  @action.bound
  removeBreakEntry(breakEntryForm) {
    if (!this.breakAddedOrRemoved) {
      this.breakAddedOrRemoved = true;
    }
    this.breaks.remove(breakEntryForm);
    if (!this.breaks.length) {
      this.addBreak();
    }
  }

  @action.bound
  setBreaks(breaks) {
    if (breaks.length) {
      breaks.forEach(breakEntry => {
        this.addBreak(breakEntry);
      });
    } else {
      this.addBreak();
    }
  }

  @action.bound
  addBreak(breakEntry) {
    if (!this.breakAddedOrRemoved && !breakEntry && this.breaks.length !== 0) {
      this.breakAddedOrRemoved = true;
    }
    const timeEntriesBreakForm = new TimeEntriesBreakForm(
      {
        fields: timeEntriesBreakFormFields,
        values: breakEntry ? breakEntry.formValues : undefined
      },
      {
        options: timeEntriesBreakFormOptions,
        plugins: timeEntriesBreakFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.breaks.push(timeEntriesBreakForm);
  }

  @computed
  get unpaidBreaksCount() {
    const unpaudBreaksCount = this.breaks.reduce(
      (unpaidDuration, breakEntry) => {
        if (!breakEntry.$('billable').value && breakEntry.$('duration').value) {
          return (unpaidDuration += breakEntry.$('duration').value);
        } else {
          return unpaidDuration;
        }
      },
      0
    );
    // We need this counter in hours
    return abbreviateNumber(unpaudBreaksCount / 60);
  }

  @computed
  get breaksAreInvalid() {
    if (this.breaks.length > 99) return true;
    return this.breaks.find(breakEntryForm => breakEntryForm.hasError)
      ? true
      : false;
  }

  @computed
  get hasEmptyBreaks() {
    return (
      this.breaks.length === 1 &&
      !this.breaks[0].values().breakType &&
      !this.breaks[0].values().startTime &&
      !this.breaks[0].values().endTime &&
      !this.breaks[0].values().duration
    );
  }
  @computed
  get hasMinimumBreaksDurationViolation() {
    return this.breaks?.find(
      breakEntryForm => breakEntryForm.hasMinimumBreakDurationViolation
    );
  }

  @action.bound
  handleAddPayrollNote() {
    this.addingPayrollNotes = true;
  }

  @action.bound
  handleClosePayrollNote() {
    this.addingPayrollNotes = false;
    this.$('note').clear();
  }

  @computed
  get showPayrollNote() {
    return Boolean(
      this.$('note').value || this.hasAttachments || this.addingPayrollNotes
    );
  }

  @computed
  get hasAttachments() {
    return this.attachments.hasModels;
  }

  @computed
  get hasSavedShift() {
    return Boolean(
      this.timeEntries.find(timeEntryForm => timeEntryForm.hasSavedShift)
    );
  }

  @computed
  get hasOnlyOneTimeEntry() {
    return this.timeEntries.length === 1;
  }

  @action.bound
  async uploadAttachment(uploadItem) {
    await this.rootStore.authorizationUI.checkFeatureAccess(
      'UploadAttachments'
    );

    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'error',
        icon: 'notification',
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    // Create a temporrary model to display the preview icon
    // in the carousel
    const previewAttachment = new Attachment(
      {
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    // Add to the collection will remove after full upload finishes
    this.attachments.add(previewAttachment);

    return this.attachments
      .upload(
        uploadItem,
        percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        },
        file,
        'TimeCard'
      )
      .then(model => {
        // Remove the preview model now that the new one is ready
        this.attachments.remove(previewAttachment);
      });
  }

  @computed
  get timeEntriesTotalHours() {
    const totalHours = this.timeEntries.reduce((totalHours, timeEntry) => {
      return timeEntry.$('hours').value
        ? totalHours + timeEntry.$('hours').value
        : totalHours;
    }, 0);
    return abbreviateNumber(totalHours);
  }

  @computed
  get totalHours() {
    if (this.hideTimeEntriesTable) {
      return this.hoursWorked;
    } else {
      return this.timeEntriesTotalHours;
    }
  }

  @computed
  get cleanedValues() {
    return {
      note: this.$('note').value ? this.$('note').value.trim() : null,
      startTime: this.$('startTime').value || null,
      endTime: this.$('endTime').value || null,
      worker: { uuid: this.worker.workerUuid },
      totalHours: abbreviateNumber(this.totalHours),
      attachments: this.attachments.models.map(attachment => {
        return { uuid: attachment.uuid };
      }),
      timeEntries: this.isZeroHourTimeCardWithUnallocatedHours
        ? []
        : this.orderedTimeEntries.map(timeEntry => timeEntry.cleanedValues),
      breaks: this.breaks
        .filter(breakEntry => breakEntry.hasBreakType)
        .map(breakEntry => breakEntry.cleanedValues),
      ...(this.$('uuid').value && {
        uuid: this.$('uuid').value
      })
    };
  }

  @computed
  get project() {
    return (
      this.rootStore.projectUI.project ||
      this.timeEntriesWorkLogUI.timeSheetsAddProject
    );
  }

  @computed
  get company() {
    return this.rootStore.me.company;
  }

  @computed get policiesEnabled() {
    return this.company.preferences.timePolicyStatus === 'ENABLED';
  }

  @computed
  get showStartEndTimeTable() {
    if (
      this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.policiesEnabled
    ) {
      if (this.$('startTime').value || this.$('endTime').value) {
        return true;
      } else if (this.totalHours) {
        return false;
      } else {
        return !this.policy?.basicSupervisorEntries;
      }
    } else {
      if (this.project?.timeCardsStartAndEndTime) {
        return this.project.timeCardsStartAndEndTime;
      } else {
        return this.hasSavedTime;
      }
    }
  }

  @computed
  get isZeroHourTimeCardWithUnallocatedHours() {
    if (this.bulkEditMode) return false;

    return (
      (this.showHoursWorked && this.hoursWorked === 0) ||
      (this.hideTimeEntriesTable &&
        this.showStartEndTimeTable &&
        this.$('startTime').value === this.$('endTime').value)
    );
  }

  @computed
  get requireCostCodesOnSomeTimeEntriesWarning() {
    if (
      this.isZeroHourTimeCardWithUnallocatedHours ||
      (this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES &&
        this.policiesEnabled)
    ) {
      return false;
    }

    return (
      this.project?.costCodesOnTimeCards === 'REQUIRED_ON_ONE' &&
      !this.timeEntries.some(timeEntryForm => {
        return timeEntryForm.values().costCode?.uuid;
      })
    );
  }

  @computed
  get requireCostCodesOnAllTimeEntriesWarning() {
    if (this.isZeroHourTimeCardWithUnallocatedHours) {
      return false;
    }

    if (
      this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.policiesEnabled
    ) {
      return (
        this.policy?.requireCostCodes &&
        !this.timeEntries.every(timeEntryForm => {
          return timeEntryForm.values().costCode?.uuid;
        })
      );
    } else {
      return (
        this.project?.costCodesOnTimeCards === 'REQUIRED_ON_ALL' &&
        !this.timeEntries.every(timeEntryForm => {
          return timeEntryForm.values().costCode?.uuid;
        })
      );
    }
  }

  @computed get requireCostCodesWarning() {
    return (
      this.requireCostCodesOnSomeTimeEntriesWarning ||
      this.requireCostCodesOnAllTimeEntriesWarning
    );
  }

  @computed get requireCostCodesWarningText() {
    if (this.requireCostCodesOnSomeTimeEntriesWarning) {
      return t(
        'Cost Codes Required: One or more cost codes are required to save this time card'
      );
    }

    return t(
      'Cost Codes Required: Cost codes are required on all time entries in order to save this time card'
    );
  }

  @computed
  get requireClassificationsOnSomeTimeEntriesWarning() {
    if (this.isZeroHourTimeCardWithUnallocatedHours) {
      return false;
    }

    return (
      this.project?.classificationsOnTimeCards === 'REQUIRED_ON_ONE' &&
      !this.timeEntries.some(timeEntryForm => {
        return timeEntryForm.values().classification?.uuid;
      })
    );
  }

  @computed
  get requireClassificationsOnAllTimeEntriesWarning() {
    if (this.isZeroHourTimeCardWithUnallocatedHours) {
      return false;
    }

    if (
      this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.policiesEnabled
    ) {
      return (
        this.company.preferences.requireClassificationsOnTimeCards &&
        !this.timeEntries.every(timeEntryForm => {
          return timeEntryForm.values().classification?.uuid;
        })
      );
    }

    return (
      this.project?.classificationsOnTimeCards === 'REQUIRED_ON_ALL' &&
      !this.timeEntries.every(timeEntryForm => {
        return timeEntryForm.values().classification?.uuid;
      })
    );
  }

  @computed get requireClassificationsWarning() {
    return (
      this.requireClassificationsOnSomeTimeEntriesWarning ||
      this.requireClassificationsOnAllTimeEntriesWarning
    );
  }

  @computed get requireClassificationsWarningText() {
    if (this.requireClassificationsOnSomeTimeEntriesWarning) {
      return t(
        'Classifications Required: One or more classifications are required to save this time card'
      );
    }

    return t(
      'Classifications Required: Classifications are required on all time entries in order to save this time card'
    );
  }

  @computed get filteredBreaks() {
    if (
      this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.policiesEnabled
    ) {
      return this.policy?.formattedBreakTypes.filter(
        breakType => breakType.status === 'ACTIVE'
      );
    } else {
      return this.rootStore.breakSelectorUI.breaks.models.filter(
        model => model.assignedToCurrentProject
      );
    }
  }

  @computed get breakOptions() {
    const options = this.filteredBreaks.map(breakModel => {
      return {
        uuid: breakModel.uuid,
        name: breakModel.name,
        formattedName: breakModel.formattedName,
        defaultDuration: breakModel.defaultDuration,
        durationRequired: breakModel.durationRequired,
        minimumBreakDuration: breakModel.minimumBreakDuration,
        startTimeRequired: breakModel.startTimeRequired,
        endTimeRequired: breakModel.endTimeRequired,
        billable: breakModel.billable
      };
    });

    return orderBy(options, ['name'], ['asc']);
  }

  @computed
  get addBreakDisabled() {
    return this.breaks.length >= 99;
  }

  @computed
  get showManageBreaks() {
    return this.filteredBreaks?.length > 0;
  }

  @computed
  get showBreakTags() {
    return (
      this.breaks.length > 0 &&
      !Boolean(this.breaks.find(breakForm => !breakForm.hasBreakType))
    );
  }

  @computed
  get bulkEditFormValues() {
    const breaksValues = this.breaks.map(breakEntry => {
      const breakEntryValues = breakEntry.values();
      // uuid is unique for each break or time entry, so in order to define are time cards equal or not,
      // we want to exclude it from compared values
      delete breakEntryValues.uuid;
      return { formValues: breakEntryValues };
    });

    const timeEntriesValues = this.timeEntries.map(timeEntry => {
      const timeEntryValues = timeEntry.values();
      delete timeEntryValues.uuid;
      return { formValues: timeEntryValues };
    });

    return {
      startTime: this.$('startTime').value,
      endTime: this.$('endTime').value,
      hoursWorked: this.$('hoursWorked').value,
      note: this.$('note').value,
      breaks: breaksValues,
      timeEntries: timeEntriesValues
    };
  }

  @computed
  get timeFrameInMinutes() {
    const startTime = this.$('startTime').value;
    const endTime = this.$('endTime').value;

    if (startTime && endTime) {
      const momentStartTime = moment(startTime, 'HH:mm');
      const momentEndTime = moment(endTime, 'HH:mm');

      const difference = moment.duration(momentEndTime.diff(momentStartTime));
      const differenceInMinutes = difference.asMinutes();
      //1440 min === 24 hours
      if (differenceInMinutes === 0) return 0;

      if (Math.sign(differenceInMinutes) > 0) {
        return differenceInMinutes;
      } else {
        // differenceInMinutes is a negative number, then calculate it as the End Time is the next day
        return 1440 + differenceInMinutes;
      }
    }
    return 0;
  }

  @computed
  get validEndTime() {
    const momentStartTime = moment(this.$('startTime').value, 'HH:mm');
    const momentEndTime = momentStartTime.add(
      this.totalHours + this.unpaidBreaksCount,
      'hours'
    );

    return momentEndTime.format('HH:mm');
  }

  @computed
  get paidHoursFromTimeFrame() {
    const paidHoursInMinutes =
      this.timeFrameInMinutes - this.unpaidBreaksInMinutes;
    if (Math.sign(paidHoursInMinutes) > 0) {
      return abbreviateNumber(paidHoursInMinutes / 60);
    }

    return 0;
  }

  @computed
  get totalTimeEntriesInMinutes() {
    return this.totalHours * 60;
  }

  @computed
  get unpaidBreaksInMinutes() {
    return this.unpaidBreaksCount * 60;
  }

  @computed
  get timeFrameWithinGracePeriod() {
    const delta =
      this.timeFrameInMinutes -
      this.totalTimeEntriesInMinutes -
      this.unpaidBreaksInMinutes;
    return delta >= -5 && delta <= 5;
  }

  @computed
  get displayedValidEndTime() {
    return moment(this.validEndTime, 'HH:mm').format('hh:mm A');
  }

  @action.bound
  setValidEndTime() {
    callTrack(TIMECARD_END_TIME_SUGGESTION_ACCEPTED);

    this.$('endTime').set(this.validEndTime);

    this.timeEntries.map(timeEntryForm => {
      timeEntryForm.init(timeEntryForm.values());
    });
  }

  @computed
  get endTimeIsValid() {
    return this.$('endTime').isValid && this.timeFrameWithinGracePeriod;
  }

  @computed
  get showTimeSuggestionTip() {
    if (this.hasKioskActivity && !this.$('endTime').value) {
      return false;
    }
    return this.$('startTime').value && !this.endTimeIsValid;
  }

  @action.bound
  showTimeSuggestionTipOpenedEvent() {
    callTrack(TIMECARD_END_TIME_SUGGESTION_OPENED);
  }

  @computed
  get disableAddHours() {
    return (
      this.showStartEndTimeTable &&
      (!this.$('startTime').value || !this.$('endTime').value)
    );
  }

  @computed
  get savedTotalWeekHours() {
    const noSavedTotalWeekHours = {
      totalHours: 0,
      weeklyHours: [],
      dailyHours: []
    };

    if (this.bulkEditMode) return noSavedTotalWeekHours;
    const savedTotalWeekHours = this.timeEntriesWorkLogUI.workersWeekTotalHours.find(
      weekHours => weekHours.workerUuid === this.worker?.workerUuid
    );

    return Boolean(savedTotalWeekHours)
      ? savedTotalWeekHours
      : noSavedTotalWeekHours;
  }

  @computed
  get totalWeekHours() {
    const totalWeekHours =
      this.savedTotalWeekHours.totalHours +
      this.totalHours -
      this.initialTotalHours;

    return abbreviateNumber(totalWeekHours);
  }

  @computed
  get hoursWorked() {
    const hoursWorked = this.$('hoursWorked').value || 0;
    return abbreviateNumber(hoursWorked);
  }

  @computed
  get showHoursLimitWarning() {
    return this.totalHours > 24 || this.hoursWorked > 24;
  }

  @computed
  get classificationName() {
    return this.worker.classificationName;
  }

  @computed
  get timeEntriesClassificationNames() {
    return this.timeEntries.map(timeEntryForm => {
      return timeEntryForm.values().classification?.name;
    });
  }

  @computed
  get timeCardHoursFromNow() {
    const now = moment();
    const timeCardDateTime = `${this.currentTimeEntriesWorkLogDate}T${
      this.$('startTime').value
    }`;

    return now.diff(timeCardDateTime, 'hours');
  }

  @computed get fromKioskOrTimeClock() {
    return this.hasKioskActivity || this.hasTimeClockActivity;
  }

  @computed get originText() {
    let origins = [];

    if (this.hasKioskActivity) {
      origins.push('Kiosk');
    }

    if (this.hasTimeClockActivity) {
      origins.push('Time clock');
    }

    if (this.manualTimeEntry) {
      origins.push('Manually entered');
    }

    return origins.join(', ');
  }

  @computed
  get kioskTimeClockStatuses() {
    if (!this.fromKioskOrTimeClock) return [];

    let statuses = [];

    if (!this.$('endTime').value && this.timeCardHoursFromNow >= 24) {
      statuses.push('MoreThan24Hours');
    }

    if (!this.$('endTime').value) {
      statuses.push('OnTheClock');
    }

    if (this.timeCardDetails.outOfProjectArea) {
      statuses.push('OutOfProjectArea');
    }

    if (this.timeCardDetails.noGpsFix) {
      statuses.push('NoGpsFix');
    }

    return statuses;
  }

  @computed get showWarningIcon() {
    return (
      this.kioskTimeClockStatuses.includes('OutOfProjectArea') ||
      this.kioskTimeClockStatuses.includes('MoreThan24Hours')
    );
  }

  @computed get warningIconText() {
    if (
      this.kioskTimeClockStatuses.includes('OutOfProjectArea') &&
      this.kioskTimeClockStatuses.includes('MoreThan24Hours')
    ) {
      return t(
        'This time card has no end time, spans over 24 hours, and has event(s) that occurred outside of the job site'
      );
    }

    if (this.kioskTimeClockStatuses.includes('OutOfProjectArea')) {
      return t(
        'This time card has event(s) that occurred outside of the job site'
      );
    }

    if (this.kioskTimeClockStatuses.includes('MoreThan24Hours')) {
      return t('This time card has no end time and spans over 24 hours');
    }

    return '';
  }

  @computed get showOnTheClockTag() {
    return (
      this.kioskTimeClockStatuses.includes('OnTheClock') ||
      this.kioskTimeClockStatuses.includes('MoreThan24Hours')
    );
  }

  @computed get showNoGpsIcon() {
    return this.kioskTimeClockStatuses.includes('NoGpsFix');
  }

  @computed get isKioskTimeCardAndCannotBeSavedDueToBreakRules() {
    const breaksWithNoDuration = this.breaks.length
      ? this.breaks.filter(
          tcBreak =>
            tcBreak.cleanedValues.breakType.uuid &&
            !tcBreak.cleanedValues.duration
        )
      : [];

    /**
     * Kiosk time cards that have been completed (the end time is set) cannot be saved with 0-duration breaks. Even if the break allows no duration!
     */

    if (this.hasKioskActivity && this.$('endTime').value) {
      return breaksWithNoDuration.length > 0;
    }

    /**
     * Kiosk Time Cards that have no end time (in-flight Kiosk Time Cards) can be saved with one   break that has 0 duration. This is allowed even if the break requires a duration.
     * Kiosk time cards can’t be saved with 2 0-duration breaks. Even if the breaks don’t require a duration.
     */

    return this.hasKioskActivity && Boolean(breaksWithNoDuration.length > 1);
  }

  @computed
  get timeCardIsValid() {
    //timeCard form is valid
    let timeCardFormIsValid;
    if (
      !this.showStartEndTimeTable ||
      (this.hasKioskActivity && !this.$('endTime').value)
    ) {
      timeCardFormIsValid =
        this.isValid &&
        !this.showHoursLimitWarning &&
        !this.requireCostCodesWarning &&
        !this.requireClassificationsWarning;
    } else {
      timeCardFormIsValid =
        this.isValid &&
        !this.showHoursLimitWarning &&
        this.endTimeIsValid &&
        !this.requireCostCodesWarning &&
        !this.requireClassificationsWarning;
    }

    //all time entries are valid
    const timeEntriesAreValid = !Boolean(
      this.timeEntries.find(timeEntry => !timeEntry.isValid)
    );

    if (this.bulkEditMode) {
      if (this.hideTimeEntriesTable) {
        return timeCardFormIsValid;
      } else {
        return timeCardFormIsValid && timeEntriesAreValid;
      }
    }

    return (
      timeCardFormIsValid &&
      timeEntriesAreValid &&
      this.isValidOvertimeRules &&
      !this.isKioskTimeCardAndCannotBeSavedDueToBreakRules
    );
  }

  @computed
  get timeCardIsPristine() {
    const timeCardFormIsPristine = this.isPristine;

    //all time entries are valid
    const timeEntrieFormsArePristine = !Boolean(
      this.timeEntries.find(timeEntry => !timeEntry.isPristine)
    );

    const breaksFormsArePristine = !Boolean(
      this.breaks.find(breakEntry => !breakEntry.isPristine)
    );

    return (
      timeCardFormIsPristine &&
      timeEntrieFormsArePristine &&
      breaksFormsArePristine &&
      !this.timeEntryAddedOrRemoved &&
      !this.breakAddedOrRemoved
    );
  }

  @computed
  get timeCardIsNotPristine() {
    return !this.timeCardIsPristine;
  }

  @computed
  get isApproved() {
    return this.timeCardStatus?.status === 'APPROVED';
  }

  @computed
  get approverFullName() {
    return this.timeCardStatus?.approvedBy?.fullName;
  }

  get workerFullName() {
    return this.worker.fullName;
  }

  @computed
  get hasAttachmentsUploading() {
    return this.attachments.models.find(attachment => attachment.isNew);
  }

  @action.bound
  showAllocatedHours() {
    when(
      () => !this.worker.settings.fetchingWorkerShiftsAndCostCodes,
      () => {
        this.showHoursWorked = false;
        this.hideTimeEntriesTable = false;

        if (this.showStartEndTimeTable) {
          this.tearDownEndTimeReaction();
          this.tearDownStartTimeReaction();
          this.tearDownBreakReaction();
        }

        this.allocateHoursWorked(true);
      }
    );
  }

  @computed
  get policy() {
    if (this.bulkEditMode) {
      return this.timeEntriesWorkLogUI.policyToBulkEdit;
    }

    const workerPolicy = this.timeEntriesWorkLogUI.policies?.models.find(
      model => model.uuid === this.worker.settings.workerDefaultTimePolicy
    );

    return workerPolicy
      ? workerPolicy
      : this.timeEntriesWorkLogUI.policies?.models.find(model => model.default);
  }

  @computed
  get overtimeRules() {
    if (
      this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.policiesEnabled
    ) {
      return this.policy?.overtimeRuleSet?.rules;
    } else {
      return this.timeEntriesWorkLogUI.overtimeRule?.rules;
    }
  }

  @computed
  get activeOverTimeRule() {
    if (
      this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.policiesEnabled
    ) {
      return this.policy?.overtimeRuleSet?.type;
    } else {
      return this.timeEntriesWorkLogUI.overtimeRule?.type;
    }
  }

  @computed
  get hasOvertimeRules() {
    return this.overtimeRules?.length > 0;
  }

  @computed
  get timeEntriesHoursAndPayTypesMap() {
    let timeEntriesHoursAndPayTypesMap = {};
    for (const timeEntryForm of this.timeEntries) {
      const timeEntryPayTypeUuid = timeEntryForm.$('payType').value.uuid;
      const timeEntryHours = timeEntryForm.$('hours').value;
      timeEntriesHoursAndPayTypesMap[timeEntryPayTypeUuid] =
        timeEntriesHoursAndPayTypesMap[timeEntryPayTypeUuid] + timeEntryHours ||
        timeEntryHours;
    }
    return timeEntriesHoursAndPayTypesMap;
  }

  @action.bound
  getTimeEntriesHoursAndPayTypesMap() {
    let timeEntriesHoursAndPayTypesMap = {};
    for (const timeEntryForm of this.timeEntries) {
      const timeEntryPayTypeUuid = timeEntryForm.$('payType').value.uuid;
      const timeEntryHours = timeEntryForm.$('hours').value;
      timeEntriesHoursAndPayTypesMap[timeEntryPayTypeUuid] =
        timeEntriesHoursAndPayTypesMap[timeEntryPayTypeUuid] + timeEntryHours ||
        timeEntryHours;
    }
    return timeEntriesHoursAndPayTypesMap;
  }

  @computed
  get isValidOvertimeRules() {
    let isValid = true;

    for (let timeEntryPayTypeUuid in this.timeEntriesHoursAndPayTypesMap) {
      const timeEntryHours = this.timeEntriesHoursAndPayTypesMap[
        timeEntryPayTypeUuid
      ];

      const overtimeRuleBalance = this.overTimeRuleHoursBalance.find(
        overtimeRuleBalance => {
          return (
            overtimeRuleBalance.payTypeOption.uuid === timeEntryPayTypeUuid
          );
        }
      );

      if (Boolean(overtimeRuleBalance)) {
        if (timeEntryHours > overtimeRuleBalance.availableHours) {
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  }

  @computed
  get showOvertimeRulesWarning() {
    return (
      !this.hideTimeEntriesTable &&
      this.hasOvertimeRules &&
      !this.showHoursLimitWarning &&
      !this.isValidOvertimeRules
    );
  }

  @computed
  get overTimeRuleHoursBalance() {
    if (!this.hasOvertimeRules) return [];
    const overTimeRuleHoursBalance = [];

    for (let rule of this.overtimeRules) {
      if (!rule.default) {
        const payType = rule.payType;
        let availableHours = 0;

        const savedHours =
          this.savedTimeEntriesHoursAndPayTypesMap[payType.uuid] || 0;

        //Daily
        const dailyHours = this.savedTotalWeekHours?.dailyHours || [];
        const payTypeSpentDaily = dailyHours.find(
          hours => hours.payType.uuid === payType.uuid
        );
        const spentDailyHours = Boolean(payTypeSpentDaily)
          ? payTypeSpentDaily.hours
          : 0;

        //Weekly
        const weeklyHours = this.savedTotalWeekHours?.weeklyHours || [];
        const payTypeSpentWeekly = weeklyHours.find(
          hours => hours.payType.uuid === payType.uuid
        );
        const spentWeeklyHours = Boolean(payTypeSpentWeekly)
          ? payTypeSpentWeekly.hours
          : 0;

        if (this.activeOverTimeRule === 'DAILY') {
          availableHours = rule.dailyLimit - spentDailyHours + savedHours;
        }

        if (this.activeOverTimeRule === 'WEEKLY') {
          availableHours = rule.weeklyLimit - spentWeeklyHours + savedHours;
        }

        if (this.activeOverTimeRule === 'BOTH') {
          const dailyBalance = rule.dailyLimit - spentDailyHours + savedHours;
          const weeklyBalance =
            rule.weeklyLimit - spentWeeklyHours + savedHours;
          availableHours = Math.min(dailyBalance, weeklyBalance);
        }

        overTimeRuleHoursBalance.push({
          order: rule.order,
          payTypeOption: {
            uuid: payType.uuid,
            name: payType.name
          },
          availableHours: availableHours > 0 ? availableHours : 0
        });
      }
    }

    return overTimeRuleHoursBalance;
  }

  @computed
  get defaultOvertimeRulePayType() {
    const defaultRule = this.overtimeRules.find(rule => rule.default);

    return {
      uuid: defaultRule.payType.uuid,
      name: defaultRule.payType.name
    };
  }

  @computed
  get firstAvailablePayType() {
    const availableHoursPayType = this.overTimeRuleHoursBalance.find(
      overtimeRuleBalance => {
        return overtimeRuleBalance.availableHours > 0;
      }
    );

    if (availableHoursPayType) {
      return availableHoursPayType.payTypeOption;
    } else {
      return this.defaultOvertimeRulePayType;
    }
  }

  @action.bound
  allocateHoursWorked(addTimeEntriesForZeroHours = 0) {
    let hoursWorkedBalance = this.hoursWorked;

    this.timeEntries.clear();

    const timeEntryFormValues = {
      classification:
        this.worker?.defaultClassification || this.defaultClassification,
      shift: this.worker?.defaultShift || null,
      costCode: this.workerDefaultCostCode
    };

    /**
     * Handle 0 hours timecards by assigning the 0 hour entry to the correct first Over Time Rule.
     */
    if (hoursWorkedBalance === 0 && addTimeEntriesForZeroHours) {
      this.addTimeEntry({
        formValues: {
          ...timeEntryFormValues,
          hours: abbreviateNumber(hoursWorkedBalance),
          payType: this.firstAvailablePayType
        }
      });

      return;
    }

    for (let overtimeRule of this.overTimeRuleHoursBalance) {
      let timeEntryHours;

      if (overtimeRule.availableHours > 0 && hoursWorkedBalance > 0) {
        if (overtimeRule.availableHours < hoursWorkedBalance) {
          timeEntryHours = overtimeRule.availableHours;
          hoursWorkedBalance = hoursWorkedBalance - overtimeRule.availableHours;
        } else {
          timeEntryHours = hoursWorkedBalance;
          hoursWorkedBalance = 0;
        }
        this.addTimeEntry({
          formValues: {
            ...timeEntryFormValues,
            hours: abbreviateNumber(timeEntryHours),
            payType: overtimeRule.payTypeOption
          }
        });
      }
    }

    //if we still have available hoursWorked, allocate it to the rule default payType
    if (hoursWorkedBalance > 0) {
      this.addTimeEntry({
        formValues: {
          ...timeEntryFormValues,
          hours: abbreviateNumber(hoursWorkedBalance),
          payType: this.defaultOvertimeRulePayType
        }
      });
    }
  }

  @action.bound
  recursiveTimeEntriesUpdate(timeEntries) {
    const updatedTimeEntries = timeEntries;
    //time entries that are cheked and valid. We need it on order to move some remaning hours from invalid time entries if possible
    const checkedTimeEntries = [];
    let isUpdated = false;

    for (const timeEntry of updatedTimeEntries) {
      const { hours, costCode, classification, payType, shift } = timeEntry;

      //check if time entry has hours limitations, else - go to the next time entry
      const overtimeRuleBalance = this.overTimeRuleHoursBalance.find(
        overtimeRuleBalance => {
          return overtimeRuleBalance.payTypeOption.uuid === payType.uuid;
        }
      );
      if (Boolean(overtimeRuleBalance)) {
        let remainingHours = 0;

        //check if the current payType has been checked in previous iterations
        const hasPreviouslyCheckedTimeEntry = Boolean(
          checkedTimeEntries.find(
            checkedTimeEntry => checkedTimeEntry.payType.uuid === payType.uuid
          )
        );
        if (hasPreviouslyCheckedTimeEntry) {
          const checkedTotalHours = checkedTimeEntries.reduce(
            (totalHours, checkedTimeEntry) => {
              if (checkedTimeEntry.payType.uuid === payType.uuid) {
                return totalHours + checkedTimeEntry.hours;
              }
              return totalHours;
            },
            0
          );

          //check if current time entry hours and checkedTotalHours are more than available hours
          if (checkedTotalHours + hours > overtimeRuleBalance.availableHours) {
            timeEntry.hours =
              overtimeRuleBalance.availableHours - checkedTotalHours;

            //if initial hours more than 0 and result hours equal 0, mark time entry for deleting
            if (hours > 0 && timeEntry.hours === 0) {
              timeEntry.delete = true;
            }
            remainingHours =
              checkedTotalHours + hours - overtimeRuleBalance.availableHours;
          } else {
            // go to the next time entry
            checkedTimeEntries.push(timeEntry);
            continue;
          }
        } else {
          //if there is no time entry in checkedTimeEntries
          //check if a current time entry hours are more than available hours
          if (hours > overtimeRuleBalance.availableHours) {
            timeEntry.hours = overtimeRuleBalance.availableHours;
            //if initial hours more than 0 and result hours equal 0, mark time entry for deleting
            if (hours > 0 && timeEntry.hours === 0) {
              timeEntry.delete = true;
            }
            remainingHours = hours - overtimeRuleBalance.availableHours;
          } else {
            // go to the next time entry
            checkedTimeEntries.push(timeEntry);
            continue;
          }
        }

        // continue with remaining hours number (over the limit)

        //check if we have next overtime rule avilable
        const nextOvertimeRuleBalance = this.overTimeRuleHoursBalance.find(
          nextOvertimeRuleBalance => {
            return (
              nextOvertimeRuleBalance.order === overtimeRuleBalance.order + 1
            );
          }
        );
        if (Boolean(nextOvertimeRuleBalance)) {
          //next OTR available

          //check if a  time entry with a payType is already in the list
          const existingTimeEntry = updatedTimeEntries.find(
            timeEntry =>
              timeEntry.payType.uuid ===
              nextOvertimeRuleBalance.payTypeOption.uuid
          );
          if (Boolean(existingTimeEntry)) {
            //check if it's equal to the current time entry values
            const shiftsAreEqual = isEqual(existingTimeEntry.shift, shift);
            const classificationsAreEqual = isEqual(
              existingTimeEntry.classification,
              classification
            );
            const costCodeAreEqual = isEqual(
              existingTimeEntry.costCode,
              costCode
            );

            if (shiftsAreEqual && classificationsAreEqual && costCodeAreEqual) {
              //is equal
              //add remaining hours to the existing equal time entry
              const updatedHours = existingTimeEntry.hours + remainingHours;
              existingTimeEntry.hours = abbreviateNumber(updatedHours);
              isUpdated = true;
              break;
            } else {
              //not equal
              //create new time entry with an available next payType rule and remaning hours
              updatedTimeEntries.push({
                hours: abbreviateNumber(remainingHours),
                payType: nextOvertimeRuleBalance.payTypeOption,
                classification: timeEntry.classification,
                shift: timeEntry.shift,
                costCode: timeEntry.costCode
              });

              isUpdated = true;
              break;
            }
          } else {
            //create new time entry with an available next payType rule
            updatedTimeEntries.push({
              hours: abbreviateNumber(remainingHours),
              payType: nextOvertimeRuleBalance.payTypeOption,
              classification: timeEntry.classification,
              shift: timeEntry.shift,
              costCode: timeEntry.costCode
            });

            isUpdated = true;
            break;
          }
        } else {
          //if no next overtime rule available - use default rule

          //check if a  time entry with a default payType is already in the list
          const existingDefaultTimeEntry = updatedTimeEntries.find(
            timeEntry =>
              this.defaultOvertimeRulePayType.uuid === timeEntry.payType.uuid
          );
          if (Boolean(existingDefaultTimeEntry)) {
            //check if it's equal to the current time entry
            const shiftsAreEqual = isEqual(
              existingDefaultTimeEntry.shift,
              shift
            );
            const classificationsAreEqual = isEqual(
              existingDefaultTimeEntry.classification,
              classification
            );
            const costCodeAreEqual = isEqual(
              existingDefaultTimeEntry.costCode,
              costCode
            );

            if (shiftsAreEqual && classificationsAreEqual && costCodeAreEqual) {
              //is equal
              //add remaining hours to the existing time entry with a default pay type
              const updatedHours =
                existingDefaultTimeEntry.hours + remainingHours;
              existingDefaultTimeEntry.hours = abbreviateNumber(updatedHours);
              isUpdated = true;
              break;
            } else {
              //not equal
              //create time entry with a default payType
              updatedTimeEntries.push({
                hours: abbreviateNumber(remainingHours),
                payType: this.defaultOvertimeRulePayType,
                classification: timeEntry.classification,
                shift: timeEntry.shift,
                costCode: timeEntry.costCode
              });

              isUpdated = true;
              break;
            }
          } else {
            //create a new time entry with a default PayType
            updatedTimeEntries.push({
              hours: abbreviateNumber(remainingHours),
              payType: this.defaultOvertimeRulePayType,
              classification: timeEntry.classification,
              shift: timeEntry.shift,
              costCode: timeEntry.costCode
            });

            isUpdated = true;
            break;
          }
        }
      } else {
        checkedTimeEntries.push(timeEntry);
        continue;
      }
    }

    //if there are changes in the time entries list, do next iteration with an updated list
    if (isUpdated) {
      return this.recursiveTimeEntriesUpdate(updatedTimeEntries);
    }

    //if all time entries are valid after updates, return updated list
    //filter time entries marked as `delete`
    return updatedTimeEntries.filter(timeEntry => !timeEntry.delete);
  }

  @action.bound
  reAllocateHours() {
    const initialTimeEntriesValues = this.timeEntries.map(timeEntryForm => {
      const timeEntryValues = {
        ...timeEntryForm.values(),
        order: timeEntryForm.order
      };
      delete timeEntryValues.uuid;
      return timeEntryValues;
    });

    const updatedTimeEntriesValues = this.recursiveTimeEntriesUpdate(
      initialTimeEntriesValues
    );

    this.timeEntries.clear();
    this.setTimeEntries(
      updatedTimeEntriesValues.map(timeEntry => {
        const { order, ...timeEntryNoOrder } = timeEntry;
        return { formValues: timeEntryNoOrder, order: order };
      })
    );

    callTrack(TIMECARD_REALLOCATEHOURS);
  }

  @computed
  get disableShowAllocatedHours() {
    return (
      !this.$('hoursWorked').isValid ||
      (this.showStartEndTimeTable &&
        (!this.$('startTime').value || !this.$('endTime').value))
    );
  }

  @action.bound
  toggleTimeEntriesView() {
    this.hideTimeEntriesTable = !this.hideTimeEntriesTable;
  }

  @action.bound
  resetHours() {
    callTrack(TIMECARD_REALLOCATEHOURS_ACCEPTED);

    const timeEntriesToRemove = this.timeEntries.filter(
      (timeEntryForm, key) => {
        timeEntryForm.reset();

        // Remove all timeEntryForm 's reset to 0 hours as they are new.
        if (!timeEntryForm.$('hours').value && key !== 0) {
          return timeEntryForm;
        }
      }
    );

    timeEntriesToRemove.forEach(timeEntryForm => {
      this.timeEntries.remove(timeEntryForm);
    });

    let breakEndTimeCalculating = true;

    while (breakEndTimeCalculating) {
      const breakTime = this.totalHours - this.paidHoursFromTimeFrame;

      const lastTimeEntry = this.orderedTimeEntries[
        this.orderedTimeEntries.length - 1
      ];

      if (lastTimeEntry.$('hours').value - breakTime > 0) {
        lastTimeEntry
          .$('hours')
          .set(abbreviateNumber(lastTimeEntry.$('hours').value - breakTime));
        breakEndTimeCalculating = false;
      } else {
        if (this.orderedTimeEntries.length === 1) {
          lastTimeEntry.$('hours').set(0);
          breakEndTimeCalculating = false;
        } else {
          this.timeEntries.remove(lastTimeEntry);
        }
      }
    }

    if (!this.isValidOvertimeRules) {
      this.reAllocateHours();
    }
  }
}

export {
  TimeEntriesTimeCardForm,
  timeEntriesTimeCardFormRules,
  timeEntriesTimeCardFormFields,
  timeEntriesTimeCardFormOptions,
  timeEntriesTimeCardFormPlugins
};
