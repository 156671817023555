import { action, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import history from 'utils/history';

import { t } from 'utils/translate';

export default class PolicyViewUI extends UIStore {
  @computed get loading() {
    return this.parent.loading;
  }

  @computed get defaultPolicy() {
    return this.parent.defaultPolicy;
  }

  @computed get restBreakDisplayText() {
    const breakType = this.defaultPolicy.breakTypes.find(
      breakType => breakType.type === 'REST'
    );
    const compensation = breakType?.billable ? t('paid') : t('unpaid');

    return breakType
      ? `${breakType?.defaultDuration} ${t('minute')} ${compensation} ${t(
          'rest break'
        )}`
      : ``;
  }

  @computed get mealBreakDisplayText() {
    const breakType = this.defaultPolicy.breakTypes.find(
      breakType => breakType.type === 'MEAL'
    );
    const compensation = breakType?.billable ? t('paid') : t('unpaid');

    return breakType
      ? `${breakType?.defaultDuration} ${t('minute')} ${compensation} ${t(
          'meal break'
        )}`
      : ``;
  }

  @action.bound managePolicies() {
    history.push({
      pathname: `/company/time/policies/manage`
    });
  }

  @action.bound editPolicy() {
    history.push({
      pathname: `/company/time/policies/${this.defaultPolicy.uuid}/settings`
    });
  }
}
