import UIStore from 'stores/ui/UIStore';
import { action, computed } from 'mobx';

import history from 'utils/history';

export default class PolicyChildEditUI extends UIStore {
  @action.bound
  resetForm() {
    this.form.reset();
  }

  @computed get disableResetButton() {
    return this.saving || this.entryForEdit?.saving;
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.form && this.form.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed get tabs() {
    return this.parent.tabs;
  }

  @action.bound moveToNextStepIfSettingUpDefault(title) {
    if (
      this.company.hasSetupDefaultPolicy ||
      this.company.policiesEnabled ||
      !this.entryForEdit?.default
    )
      return;

    const currentIndex = this.tabs.findIndex(tab => {
      return tab.label === title;
    });

    // If current tab not found or it's the last tab, return null
    if (currentIndex === -1 || currentIndex === this.tabs.length - 1) {
      return console.log('Finished');
    }

    history.push({
      pathname: this.tabs[currentIndex + 1].path.pathname
    });
  }
}
