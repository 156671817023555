import { observer } from 'mobx-react';
import React from 'react';
import { Dialog, Button, Box, Text } from '@raken/athens-web/lib';
import PropTypes from 'prop-types';
import { t } from 'utils/translate';

const DeleteModal = observer(props => {
  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      style={{ zIndex: 2100 }} // Deprecate after removing old gallery
      maxWidth={'sm'}
      dataQA="confirm-delete"
      onClick={e => e.stopPropagation()} // Important: prevent events from leaking into elements below this modal.
      onClose={props.onEsc}
    >
      <Dialog.Title
        dataQA="modal-delete-title"
        withClose
        onClose={props.onClose}
      >
        {props.title || t('Delete?')}
      </Dialog.Title>

      <Dialog.Content>
        <Box
          dataQA="modal-delete-content"
          mb={props.confirmationCheckbox ? 6 : 0}
        >
          <Text>{props.children || `${t('Confirm delete')}?`}</Text>
        </Box>

        {props.confirmationCheckbox}
      </Dialog.Content>

      <Dialog.Actions>
        {props.renderButtons ? (
          props.renderButtons(props)
        ) : (
          <>
            <Button
              rakenColor="white"
              onClick={props.onClose}
              dataQA="modal-delete-cancel"
            >
              {t('Cancel')}
            </Button>
            <Button
              rakenColor={props.buttonColor}
              onClick={props.onDelete}
              dataQA="modal-delete-confirm"
              disabled={props.deleting}
            >
              {props.deleting ? props.buttonTextActive : props.buttonText}
            </Button>
          </>
        )}
      </Dialog.Actions>
    </Dialog>
  );
});

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onEsc: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  title: PropTypes.string,
  renderButtons: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  buttonText: PropTypes.string,
  buttonTextActive: PropTypes.string,
  buttonColor: PropTypes.string
};

DeleteModal.defaultProps = {
  buttonText: t('Delete'),
  buttonTextActive: t('Deleting'),
  buttonColor: 'red'
};

export default DeleteModal;
