import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import difference from 'lodash.difference';
import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import request from 'axios';

import PolicyChildEditUI from './PolicyChildEditUI';
import PolicyMembers from 'stores/collections/PolicyMembers';

import { t } from 'utils/translate';
import history from 'utils/history';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class PolicyEmployeesUI extends PolicyChildEditUI {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable deleting;
  @observable loading;
  @observable saving;

  @observable showSelectionExpandedAlert;
  @observable selectionExpanded;

  constructor(options) {
    super(options);

    this.loading = true;
    this.saving = false;

    this.paginatedMembers = new PolicyMembers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Bulk select all active members
    this.showSelectionExpandedAlert = false;
    this.selectionExpanded = false;

    this.selectedMembers = observable([]);
    this.selectedGroups = observable([]);

    this.selectedMembershipFilterOptions = observable([
      { id: 'ASSIGNED', title: 'Assigned' },
      { id: 'UNASSIGNED', title: 'Unassigned' }
    ]);

    this.selectedMemberStatusFilterOptions = observable([
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' }
    ]);

    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchMembersDebounced = debounce(this.fetchMembers, BASE_DEBOUNCE);
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed get members() {
    return this.paginatedMembers;
  }

  @computed get hasMembers() {
    return this.members.hasModels;
  }

  @action.bound async setup() {
    this.groupSelectorUI.setup();

    this.setupReactions();
    await this.fetchMembers();
    this.blockHistoryIfSelectedMembersChanged();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound cancelPolicyEdit() {
    this.selectedMembers.length
      ? this.parent.showModal('DiscardChangesModal')
      : this.parent.cancelPolicyEdit();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchMembers();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchMembersDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchMembers() {
    this.loading = true;

    await this.members.fetchWithPost({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      ),
      url: `ra/companies/${this.rootStore.me.company.uuid}/members/timepolicies/${this.entryForEdit.uuid}/settings`
    });

    this.setSelectedMembers();

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    this.clearSelectionExpanded();
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get params() {
    const params = {
      limit: this.pageSize,
      sortField: 'firstName,lastName',
      sortDirection: 'asc',
      role:
        'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER,ROLE_WORKER',
      offset: (this.page - 1) * this.pageSize,
      status: this.selectedMemberStatusFilterOptions.map(option => option.id),
      groupUuids: this.selectedGroups.map(option => option.uuid),
      assignedState:
        this.selectedMembershipFilterOptions.length === 1
          ? this.selectedMembershipFilterOptions[0].id
          : 'BOTH'
    };

    return params;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.members.totalElements / this.pageSize);
  }

  @action.bound setSelectedGroups(selectedGroups) {
    this.selectedGroups.replace(selectedGroups);
  }

  @action.bound
  resetSelectedMembers() {
    const uniqueUuids = [...new Set([...this.entryForEdit.workerUuids])];
    this.selectedMembers.replace(uniqueUuids);
  }

  @action.bound
  setSelectedMembers() {
    const uniqueUuids = [
      ...new Set([...this.entryForEdit.workerUuids, ...this.selectedMembers])
    ];
    this.selectedMembers.replace(uniqueUuids);
  }

  @computed get hasSelectedMembers() {
    return this.selectedMembers.length > 0;
  }

  @action.bound
  async toggleSelectMember(workerUuid) {
    this.clearSelectionExpanded();

    if (this.selectedMembers.find(uuid => uuid === workerUuid)) {
      this.selectedMembers.remove(workerUuid);
    } else {
      this.selectedMembers.push(workerUuid);
    }
  }

  @computed
  get allMembersSelected() {
    return (
      this.hasMembers &&
      this.members.models.every(member =>
        this.selectedMembers.includes(member.workerUuid)
      )
    );
  }

  @action.bound
  async toggleSelectAllMembers() {
    if (this.allMembersSelected) {
      this.clearSelectionExpanded();

      this.selectedMembers.replace(
        this.selectedMembers.filter(workerUuid => {
          return !this.members.models
            .map(member => member.workerUuid)
            .includes(workerUuid);
        })
      );
    } else {
      if (this.page === 1) {
        this.showSelectionExpandedAlert = true;
      }

      this.members.models.forEach(member => {
        if (!this.selectedMembers.includes(member.workerUuid)) {
          this.selectedMembers.push(member.workerUuid);
        }
      });
    }
  }

  @computed get membersNotOnPage() {
    return this.paginatedMembers.totalElements - this.paginatedMembers.length;
  }

  @action.bound expandSelection() {
    this.selectionExpanded = true;
  }

  @action.bound clearSelectionExpanded() {
    this.showSelectionExpandedAlert = false;
    this.selectionExpanded = false;
  }

  @computed get selectionExpandedAlertText() {
    if (this.selectionExpanded) {
      return t(
        `{membersCount} employees are selected. Would you like to clear your selection?`,
        {
          templateStrings: {
            membersCount: this.paginatedMembers.totalElements
          }
        }
      );
    }

    return t(
      `All {membersOnPage} employees on this page are selected. Would you like to expand the selection to include {membersNotOnPage} employees not shown?`,
      {
        templateStrings: {
          membersOnPage: this.paginatedMembers.length,
          MembersNotOnPage: this.membersNotOnPage
        }
      }
    );
  }

  @action.bound clearAllSelectedMembers() {
    this.clearSelectionExpanded();
    this.selectedMembers.clear();
  }

  @action.bound async saveMembers(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.saving) return;

    this.clearValidationDetails();

    this.saving = true;

    let payload;

    if (this.selectionExpanded) {
      payload = {
        addToMemberStatuses: this.hasSelectedMemberStatusFilterOptions
          ? this.selectedMemberStatusFilterOptions.map(option => option.id)
          : this.memberStatusFilterOptions.map(option => option.id)
      };
    } else {
      payload = {
        workerUuids: this.selectedMembers.slice()
      };
    }

    try {
      await request.post(
        `/ra/companies/${this.rootStore.me.company.uuid}/members/timepolicies/${this.entryForEdit.uuid}/settings/batch`,
        payload
      );

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Employees saved')
      });

      this.refetchAfterSave();
      this.parent.parent.refetchPolicies();

      if (this.parent.setupFirstPolicy) {
        // If we are setting up a default policy.
        await this.company.save(
          { preferences: { timePolicyStatus: 'SETUP' } },
          {
            wait: true,
            url: this.company.url() + '/preferences'
          }
        );

        this.showModal('createNewPolicyModal');
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get saveButtonText() {
    return this.saving
      ? t('Saving...')
      : this.parent.setupFirstPolicy
      ? t('Save & finish')
      : t('Save');
  }

  @action.bound async refetchAfterSave() {
    this.page = 1;
    this.loading = true;
    this.clearValidationDetails();
    this.searchQuery = '';
    this.selectedMembers.clear();
    this.clearSelectionExpanded();
    await this.entryForEdit.fetch();
    this.setSelectedMembers();
    this.loading = false;
  }

  @action.bound cancelMembers() {
    this.setSelectedMembers();
    this.clearSearchQuery();
    this.clearSelectionExpanded();
  }

  @action.bound clearUIState() {
    this.page = 1;
    this.loading = true;
    this.groupSelectorUI.tearDown();
    this.clearValidationDetails();
    this.searchQuery = '';
    this.selectedMembers.clear();
    this.selectedGroups.clear();
    this.selectedMemberStatusFilterOptions.replace([
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' }
    ]);
    this.members.clear();
    this.clearSelectionExpanded();
  }

  @computed get membershipFilterOptions() {
    return [
      { id: 'ASSIGNED', title: 'Assigned' },
      { id: 'UNASSIGNED', title: 'Unassigned' }
    ];
  }

  @action.bound selectMembershipFilterOptions(options) {
    this.selectedMembershipFilterOptions.replace(options);
    this.clearSelectionExpanded();
    this.page = 1;
  }

  @computed get memberStatusFilterOptions() {
    return [
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' },
      { id: 'INACTIVE', title: 'Inactive' },
      { id: 'DELETED', title: 'Deleted' }
    ];
  }

  @action.bound selectMemberStatusFilterOptions(options) {
    this.selectedMemberStatusFilterOptions.replace(options);
    this.clearSelectionExpanded();
    this.page = 1;
  }

  @computed get hasSelectedMemberStatusFilterOptions() {
    return this.selectedMemberStatusFilterOptions.length > 0;
  }

  @computed get selectedMembersChanged() {
    if (this.entryForEdit.workerUuids.length !== this.selectedMembers.length)
      return true;

    return (
      difference(
        this.entryForEdit.workerUuids.slice(),
        this.selectedMembers.slice()
      ).length > 0
    );
  }

  @action.bound blockHistoryIfSelectedMembersChanged() {
    this.unblock = history.block((location, action) => {
      if (this.selectedMembersChanged) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed get disableSaveButton() {
    return this.saving;
  }

  @action.bound hideCreateNewPolicyModal() {
    history.push({
      pathname: `/company/time/policies/manage`
    });

    this.hideActiveModal();
    this.cancelPolicyEdit();
  }

  @action.bound createANewPolicy() {
    this.parent.parent.addPolicy();
  }
}
