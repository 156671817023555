import UIStore from './UIStore';
import { action, computed } from 'mobx';

import ReportDownloadUI from './ReportDropdownUI';
import ManpowerReportsUI from './ManpowerReportsUI';
import ProjectSummaryReportsUI from './ProjectSummaryReportsUI';
import TasksReportsUI from './TasksReportsUI';
import ToolboxTalksReportsUI from './ToolboxTalksReportsUI';
import ProductionQuantitiesReportsUI from './ProductionQuantitiesReportsUI';
import EquipmentLogReportsUI from './EquipmentLogReportsUI';
import EquipmentSnapshotReportsUI from './EquipmentSnapshotReportsUI';
import SmallEquipmentSnapshotReportsUI from './SmallEquipmentSnapshotReportsUI';
import SmallEquipmentHistoryReportsUI from './SmallEquipmentHistoryReportsUI';
import ObservationsReportsUI from './ObservationsReportsUI';
import ChecklistsReportsUI from './ChecklistsReportsUI';
import CertificationsReportsUI from './CertificationsReportsUI';
import ViolationsReportsUI from './ViolationsReportsUI';

export default class ReportsUI extends UIStore {
  constructor(options) {
    super(options);

    this.instantiateUIStores();
  }

  @action.bound
  instantiateUIStores() {
    this.reportDropdownUI = new ReportDownloadUI({
      rootStore: this.rootStore
    });
    this.manpowerReportsUI = new ManpowerReportsUI({
      rootStore: this.rootStore
    });
    this.projectSummaryReportsUI = new ProjectSummaryReportsUI({
      rootStore: this.rootStore
    });
    this.tasksReportsUI = new TasksReportsUI({
      rootStore: this.rootStore
    });
    this.toolboxTalksReportsUI = new ToolboxTalksReportsUI({
      rootStore: this.rootStore
    });
    this.productionQuantitiesReportsUI = new ProductionQuantitiesReportsUI({
      rootStore: this.rootStore
    });
    this.equipmentLogReportsUI = new EquipmentLogReportsUI({
      rootStore: this.rootStore
    });
    this.equipmentSnapshotReportsUI = new EquipmentSnapshotReportsUI({
      rootStore: this.rootStore
    });
    this.smallEquipmentSnapshotReportsUI = new SmallEquipmentSnapshotReportsUI({
      rootStore: this.rootStore
    });
    this.smallEquipmentHistoryReportsUI = new SmallEquipmentHistoryReportsUI({
      rootStore: this.rootStore
    });
    this.observationsReportsUI = new ObservationsReportsUI({
      rootStore: this.rootStore
    });
    this.checklistsReportsUI = new ChecklistsReportsUI({
      rootStore: this.rootStore
    });
    this.certificationsReportsUI = new CertificationsReportsUI({
      rootStore: this.rootStore
    });
    this.violationsReportsUI = new ViolationsReportsUI({
      rootStore: this.rootStore
    });
  }

  @computed
  get timesheetsReportUI() {
    return this.rootStore.timesheetsUI?.timesheetsReportUI;
  }
}
