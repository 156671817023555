import { action } from 'mobx';

import UIStore from 'stores/ui/UIStore';

export default class DirectoryContactsUI extends UIStore {
  constructor(options) {
    super(options);
  }

  @action.bound setup() {}

  @action.bound tearDown() {}

  // Todo(joe): Implement DirectoryContactsUI
}
