import { Model } from 'mobx-mc';
import { action, observable, computed, runInAction, reaction } from 'mobx';
import omit from 'lodash.omit';
import { t } from 'utils/translate';
import moment from 'moment-timezone';
import TimesheetTimeCards from '../collections/TimesheetTimeCards';
import kebabCase from 'lodash.kebabcase';
import abbreviateNumber from 'utils/abbreviateNumber';

import request from 'axios';
import errorHandler from 'utils/errorHandler';

export default class Timesheet extends Model {
  @observable timeCards;
  @observable selected;
  @observable expanded;

  constructor(attributes, options) {
    super(attributes, options);
    this.selected = false;
    this.expanded = false;

    this.setupSelectTimeCardsReaction();
  }

  get restAttributes() {
    return [
      'timeSheetUuid',
      'periodType',
      'pdf',
      'worker',
      'approved',
      'signed',
      'signedDate',
      'businessTimezone',
      'totalHours',
      'hasPayrollNotes',
      'approvedCount',
      'syncedCount',
      'timeCardCount',
      'hasTimeCardsWithNoGps',
      'hasTimeClockViolation',
      'hasCostCodeRuleViolation',
      'hasMinBreakDurationViolation'
    ];
  }

  idAttribute() {
    return 'timeSheetUuid';
  }

  @action.bound
  setSelected(selected) {
    runInAction(() => {
      if (this.timeCards.hasModels) {
        this.timeCards.updateSelection(selected);
      }
      this.selected = selected;
    });
  }

  @computed
  get hasPartlySelectedTimecards() {
    return this.timeCards.hasSelectedTimeCard && !this.selected;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timesheets/${this.timeSheetUuid}`;
  }

  @action.bound
  parse(attributes) {
    this.parseTimeCards(attributes);
    return {
      ...omit(attributes, ['timeCards'])
    };
  }

  @action.bound
  parseTimeCards(attributes) {
    this.timeCards = new TimesheetTimeCards(attributes.timeCards, {
      parent: this,
      rootStore: this.rootStore,
      selected: this.selected
    });
  }

  @computed
  get hasTimeCards() {
    return this.timeCards.hasModels;
  }

  @computed
  get workerFullName() {
    return `${this.worker.firstName} ${this.worker.lastName}`;
  }

  @computed
  get workerFullNameInverse() {
    return `${this.worker.lastName}, ${this.worker.firstName}`;
  }

  @computed
  get formattedSignedDate() {
    return this.formatTimestamp(this.signedDate);
  }

  formatTimestamp(timestamp) {
    const now = moment().tz(this.businessTimezone);
    const then = moment(timestamp).tz(this.businessTimezone);

    if (now.isSame(then, 'day')) {
      return t('Today at') + ' ' + then.format('h:mm A');
    }

    if (now.subtract(1, 'day').isSame(then, 'day')) {
      return t('Yesterday at') + ' ' + then.format('h:mm A');
    }

    if (now.isSame(then, 'year')) {
      return then.format('MMM D h:mm A');
    }

    return then.format('MMM D, YYYY h:mm A');
  }

  setupSelectTimeCardsReaction() {
    if (this.cancelSelectTimeCardReaction) {
      this.cancelSelectTimeCardReaction();
    }
    this.cancelSelectTimeCardReaction = reaction(
      () => this.timeCards.allTimeCardsSelected,
      allTimeCardsSelected => {
        if (allTimeCardsSelected) {
          this.selected = true;
        } else {
          this.selected = false;
        }
      }
    );
  }

  @computed
  get slug() {
    return kebabCase(this.workerFullNameInverse);
  }

  @action.bound
  fetchTimeSheet(requestParams) {
    this.fetching = true;
    return request
      .post(
        `${this.rootStore.urlMicroService('performanceTracking')}/timesheets/${
          this.timeSheetUuid
        }`,
        requestParams
      )
      .then(
        response => {
          runInAction(() => {
            this.set(response.data);
            this.fetching = false;
          });
        },
        error => {
          errorHandler(error, this.notifications.pushError);
        }
      );
  }

  @action.bound
  toggleTimeCards(requestParams) {
    if (!this.expanded) {
      this.expanded = !this.expanded;
      return this.fetchTimeSheet(requestParams);
    }
    this.expanded = !this.expanded;
    return;
  }

  @computed
  get showPayrollNoteIcon() {
    return this.hasPayrollNotes;
  }

  @computed
  get displayedTotalTime() {
    return this.totalHours.totalHours === 0
      ? '-'
      : abbreviateNumber(this.totalHours.totalHours);
  }

  @computed
  get payTypeTotalHours() {
    return this.totalHours.payTypeHours;
  }

  @computed
  get roundedPayTypeTotalHours() {
    return this.payTypeTotalHours.map(payType => {
      return {
        code: payType.code,
        hours: abbreviateNumber(payType.hours)
      };
    });
  }

  @computed
  get timesheetStatusText() {
    if (this.approvedCount === 0) {
      return t('0 of {count} approved', {
        templateStrings: {
          count: this.timeCardCount
        }
      });
    }

    if (this.approvedCount === this.timeCardCount) {
      return t('Approved');
    }

    return t('{approvedCount} of {timeCardCount} approved', {
      templateStrings: {
        approvedCount: this.approvedCount,
        timeCardCount: this.timeCardCount
      }
    });
  }

  @computed
  get timesheetSyncStatusText() {
    if (this.syncedCount === 0) {
      return t('0 of {count} synced', {
        templateStrings: {
          count: this.timeCardCount
        }
      });
    }
    if (this.syncedCount === this.timeCardCount) {
      return t('Synced');
    }
    return t('{syncedCount} of {timeCardCount} synced', {
      templateStrings: {
        syncedCount: this.syncedCount,
        timeCardCount: this.timeCardCount
      }
    });
  }

  @computed get allTimeCardsSynced() {
    return this.syncedCount === this.timeCardCount;
  }

  @computed get showTimesheetIssuesIcon() {
    return this.hasCostCodeRuleViolation || this.hasTimeClockViolation;
  }
}
