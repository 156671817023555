import validatorjs from 'validatorjs';
import moment from 'moment';
import request from 'axios';
import { t } from 'utils/translate';
import qs from 'querystringify';

// override moment.parseTwoDigitYear to default break year of 2000 to current year instead of 68
// @link: https://momentjs.com/docs/#/parsing/string-format/
moment.parseTwoDigitYear = yearString => {
  return +yearString + (+yearString > moment().format('YY') ? 1900 : 2000);
};

export const customRules = {
  domainCustom: {
    function: function(value, req) {
      try {
        const hostname = new URL(value).hostname;
        // Javascript URL seems to accept urls like: https://...google.com or https://.google.com....
        if (!hostname || hostname.startsWith('.') || hostname.endsWith('.')) {
          return false;
        }
        return hostname.toLowerCase().endsWith(req.toLowerCase());
      } catch (e) {
        return false;
      }
    },
    message: t(
      `The :attribute is invalid or not from a ':domainCustom' domain.`
    )
  },
  dateCustom: {
    function: function(value, req) {
      return moment(value, req, true).isValid();
    },
    message: t(`The date is not a valid date format :dateCustom.`)
  },
  afterCustom: {
    function: function(value, req) {
      const [name, dateFormat] = req.split(',');
      const val1 = this.validator.input[name];
      const val2 = value;

      if (!moment(val1, dateFormat, true).isValid()) {
        return false;
      }
      if (!moment(val2, dateFormat, true).isValid()) {
        return false;
      }

      if (
        moment(val1, dateFormat, true).valueOf() <
        moment(val2, dateFormat, true).valueOf()
      ) {
        return true;
      }

      return false;
    },
    message: t('The end date must be after the start date.')
  },
  afterOrSameAsCustom: {
    function: function(value, req) {
      const [name, dateFormat] = req.split(',');
      const val1 = this.validator.input[name];
      const val2 = value;

      if (!moment(val1, dateFormat, true).isValid()) {
        return false;
      }
      if (!moment(val2, dateFormat, true).isValid()) {
        return false;
      }

      if (
        moment(val1, dateFormat, true).valueOf() <=
        moment(val2, dateFormat, true).valueOf()
      ) {
        return true;
      }

      return false;
    },
    message: t('The end date must be after or the same as the from date.')
  },
  maxOneYearAfterCustom: {
    function: function(value, req) {
      const [name, dateFormat] = req.split(',');
      const val1 = this.validator.input[name];
      const val2 = value;

      if (!moment(val1, dateFormat, true).isValid()) {
        return false;
      }
      if (!moment(val2, dateFormat, true).isValid()) {
        return false;
      }

      const date1 = moment(val1); // The base date
      const date2 = moment(val2); // The date to check

      const isWithinYear = date2.isBetween(
        moment(date1).subtract(1, 'day'),
        moment(date1)
          .add(1, 'year')
          .add(1, 'day') // One year after
      );

      return isWithinYear;
    },
    message: t('The end date must be within one year of the start date.')
  },
  withinThreeMonths: {
    function: function(value, req) {
      const [name, dateFormat] = req.split(',');
      const val1 = this.validator.input[name];

      if (!moment(val1, dateFormat, true).isValid()) {
        return false;
      }

      if (!moment(value, dateFormat, true).isValid()) {
        return false;
      }

      return moment(value, dateFormat).isSameOrBefore(
        moment(val1, dateFormat).add(3, 'months')
      );
    },
    message: t('The end date must be within three months of the from date.')
  },
  withinOneYear: {
    function: function(value, req) {
      const [name, dateFormat] = req.split(',');
      const val1 = this.validator.input[name];

      if (!moment(val1, dateFormat, true).isValid()) {
        return false;
      }
      if (!moment(value, dateFormat, true).isValid()) {
        return false;
      }

      return moment(value, dateFormat).isSameOrBefore(
        moment(val1, dateFormat).add(1, 'year')
      );
    },
    message: t('The end date must be within one year of the from date.')
  },
  projectEndDate: {
    function(val, req) {
      req = this.getParameters();

      const val1 = this.validator.input[req[0]];
      const val2 = val;

      if (moment(val2, req[1]).isSameOrAfter(moment(val1, req[1]))) {
        return true;
      }

      return false;
    },
    message: t('The end date cannot be before the start date.')
  },
  equipmentDeploymentIsSameOrAfterDate: {
    function(endDate, req) {
      req = this.getParameters();
      const [fieldName, format] = req;
      const startDate = this.validator.input[fieldName];

      if (!startDate) return true;

      return moment(endDate, format).isSameOrAfter(
        moment(startDate, format),
        'day'
      );
    },
    message: t('The end date cannot be before start date.')
  },
  equipmentDeploymentIsSameOrBeforeDate: {
    function(startDate, req) {
      req = this.getParameters();
      const [fieldName, format] = req;
      const endDate = this.validator.input[fieldName];

      if (this.validator.input.status === 'REMOVED') {
        if (!endDate) return true;

        return moment(startDate, format).isSameOrBefore(
          moment(endDate, format),
          'day'
        );
      }

      return true;
    },
    message: t('The start date cannot be after end date.')
  },
  equipmentLogDate: {
    function(date, req) {
      req = this.getParameters();

      const [format, equipmentLogStartDate] = req;

      if (
        moment(date, format).isSameOrAfter(
          moment(equipmentLogStartDate, format),
          'day'
        )
      ) {
        return true;
      }
      return false;
    },
    message: t('Cannot be before the equipment log start date.')
  },
  beforeOrTodayDate: {
    function(date, format) {
      if (moment(date, format).isSameOrBefore(moment(), 'day')) {
        return true;
      }
      return false;
    },
    message: t('The date cannot be after today.')
  },
  afterOrTodayDate: {
    function(date, format) {
      if (moment(date, format).isSameOrAfter(moment(), 'day')) {
        return true;
      }
      return false;
    },
    message: t('The date cannot be before today.')
  },
  promoEndDate: {
    function(val) {
      if (moment(val).isSameOrAfter(moment())) {
        return true;
      }

      return false;
    },
    message: t('The Promo End Date cannot be before today.')
  },
  notMoreThanTwoDecimalPlaces: {
    function(val, req) {
      val = String(val);
      let fractionalPartPosition = val.indexOf('.');
      if (fractionalPartPosition !== -1) {
        let fractionalPart = val.slice(fractionalPartPosition + 1);
        return fractionalPart.length <= 2;
      } else {
        return true;
      }
    },
    message: t('The :attribute cannot contain more than two decimal places')
  },
  mustNotExceed8Digits: {
    function(val, req) {
      val = String(val);
      let fractionalPartLength = 0;
      let integerPartLength = 0;

      let fractionalPartPosition = val.indexOf('.');

      if (fractionalPartPosition === -1) {
        integerPartLength = val.length;
      } else {
        fractionalPartLength = val.slice(fractionalPartPosition + 1).length;
        integerPartLength = val.slice(0, fractionalPartPosition).length;
      }
      return integerPartLength + fractionalPartLength <= 8;
    },
    message: t('The :attribute must not exceed 8 digits')
  },
  greaterThan0: {
    function(val, req) {
      return val > 0;
    },
    message: t('The :attribute must be greater than 0.')
  },
  is: {
    function(val, req) {
      req = this.getParameters();

      if (!val) {
        return false;
      }

      if (val.toLowerCase() === req[0].toLowerCase()) {
        return true;
      }

      return false;
    },
    message: t('The selected :attribute is invalid.')
  },
  divisionCodeCombinationExist: {
    function(val, req) {
      return false;
    },
    message: t('Division-code combination already exists.')
  },
  not_in: {
    function(val, req) {
      var list = this.getParameters().filter(item => item);
      var len = list.length;
      var returnVal = true;

      for (var i = 0; i < len; i++) {
        var localValue = val;

        if (typeof list[i] === 'string') {
          localValue = String(val);
        }
        // Convert Pipe to Hex code as it will break rules when passed to dvr as string. eg. not_in:"foo|bar"
        if (
          localValue
            .replace('|', '7C')
            .trim()
            .toLowerCase() ===
          list[i]
            .replace('|', '7C')
            .trim()
            .toLowerCase()
        ) {
          returnVal = false;
          break;
        }
      }

      return returnVal;
    }
  },
  materialQuantityIsNumber: {
    function(val, req) {
      /* delete unit from value before. e.x. value='10 m²' after value='10' */
      const value = val.replace(' ' + req.trim(), '');
      return !isNaN(value);
    },
    message: t('The :attribute must be a number.')
  },
  materialQuantityIsGreaterThan0: {
    function(val, req) {
      /* delete unit from value before. e.x. value='10 m²' after value='10' */
      const value = val.replace(' ' + req.trim(), '');
      return value > 0;
    },
    message: t('The :attribute must be greater than 0.')
  },
  numberCanHaveComma: {
    function(val) {
      const regex = /^\d{1,3}(,?\d{3})*?(.\d*)?$/g;
      if (regex.test(val)) {
        return true;
      }
      return false;
    },
    message: t('The Amount must be a number.')
  },
  rakenAppEmail: {
    function(value) {
      const regex = /^[a-zA-Z0-9._-]+@rakenapp\.com$/;
      return regex.test(value);
    },
    message: t('Please enter a valid rakenapp.com email address.')
  },
  arrayHasTrue: {
    function(val, req) {
      req = this.getParameters();

      if (val.some(entry => entry[req[0]])) {
        return true;
      }

      return false;
    },
    message: t('The selected :attribute is invalid.')
  },
  minLowerCase: {
    function(val, req) {
      const requiredLength = req.trim();
      return val?.match(/([a-z])/g)?.length >= requiredLength || false;
    },
    message: t('At least :minLowerCase lower case letter')
  },
  minUpperCase: {
    function(val, req) {
      const requiredLength = req.trim();
      return val?.match(/([A-Z])/g)?.length >= requiredLength || false;
    },
    message: t('At least :minUpperCase upper case letter')
  },
  emailList: {
    function(val, req) {
      const emails = val.split(',');
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let isValid = true;

      emails.forEach(email => {
        if (!re.test(email.trim())) {
          isValid = false;
        }
      });

      return isValid;
    },
    message: t(':attribute contains one or more invalid emails.')
  },
  maxPromoOrTrialDate: {
    function: function(value, req) {
      const val1 = req;
      const val2 = value;

      if (!moment(val1, 'YYYY-MM-DD', true).isValid()) {
        return false;
      }
      if (!moment(val2, 'YYYY-MM-DD', true).isValid()) {
        return false;
      }

      if (
        moment(val1, 'YYYY-MM-DD', true).valueOf() >
        moment(val2, 'YYYY-MM-DD', true).valueOf()
      ) {
        return true;
      }

      return false;
    },
    message: t('The :attribute cannot be more than 30 days in the future.')
  },
  oshaStandard: {
    // We are using regex to blacklist certain characters
    function(val, req) {
      switch (req) {
        // Special condition to prevent specific characters
        case 'all':
          return /^[A-Za-z\d\s]+$/gm.test(val);
        default:
          return !/^.*[()~@#$%^&*?…\\]+.*$/.test(val);
      }
    },
    message: t('Please remove all special characters.')
  },
  totalHoursRatio: {
    function(value) {
      const totalHours = Number(value);
      const avgEmployees = Number(this.validator.input.annualAverageEmployees);
      if (isNaN(avgEmployees)) {
        return false;
      }
      const ratio = totalHours / avgEmployees;
      return ratio > 500 && ratio < 8760;
    },
    message: t('Total hrs worked / Avg ann. employees must be between 500-8760')
  },
  exact_digits: {
    function(value, requirement) {
      const length = Number(requirement);
      const regex = new RegExp(`^\\d{${length}}$`);
      return regex.test(value);
    },
    message: t(`The :attribute must be exactly :exact_digits digits.`)
  },
  lessThanOrEqualToTotalHours: {
    function(value, requirement) {
      const otherFieldValue = this.validator.input[requirement];

      return Number(value) <= Number(otherFieldValue);
    },
    message: t('The value must be less than or equal to the total hours.')
  },
  cannotBeLessThanTotalUnitsDeployed: {
    function(value, requirement) {
      return Number(value) >= Number(requirement);
    },
    message: t(
      `The :attribute cannot be less than total units deployed (:cannotBeLessThanTotalUnitsDeployed).`
    )
  },
  renameDocumentName: {
    function(value) {
      return !/[\\/:*?<>|]|(\.$)/g.test(value);
    },
    message: t(
      'The name may not contain the special characters /\\:*?<>|, The name cannot end in a period.'
    )
  },
  positiveOrNegative: {
    function(val, req, attribute) {
      req = this.getParameters();

      if (!this.validator.input.negative && !this.validator.input.positive) {
        return false;
      }

      return true;
    },
    message: t('Please select positive and/or negative options.')
  },
  atLeastOne: {
    function(val, req, attribute) {
      req = this.getParameters();

      let result = !this.validator.input[attribute];
      req.forEach(item => (result = result && !this.validator.input[item]));

      if (result) {
        return false;
      }

      return true;
    },
    message: t('Please select at least one option.')
  }
};

export const implicitRules = {
  oshaDateOfDeath: {
    function(val, req, attribute) {
      req = this.getParameters();

      if (this.validator._objectPath(this.validator.input, req[0]) == req[1]) {
        return this.validator.getRule('required').validate(val);
      }

      return true;
    },
    message: t(
      'The date of death field is required when the incident outcome is death.'
    )
  }
};

const asyncRules = {
  username_available: async (value, attr, key, passes) => {
    const existsMsg = t('Username already exists.');
    const errorMsg = t('Unable to verify username. Please try again later.');

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(value)) {
      passes();
      return;
    }

    try {
      await request.get(`/ra/members/username/${value}`, {
        params: {
          includePending: true,
          internal: true
        }
      });

      passes(false, existsMsg);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        passes();
      } else {
        // For other errors, fail the validation with an error message
        passes(false, errorMsg);
      }
    }
  },
  worker_email_available: function(value, attr, key, passes) {
    const msg = t('Email already exists.');

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(value)) {
      passes();
      return;
    }

    request
      .get(
        `/ra/companies/${this.validator.input.companyUuid}/members/validate`,
        {
          params: {
            key: 'email',
            value: value,
            excludeUUIDs: this.validator.input.uuid,
            status: 'ACTIVE,INVITED'
          }
        }
      )
      .then(response => {
        passes();
      })
      .catch(error => {
        error.response.status === 409 ? passes(false, msg) : passes();
      });
  },
  superUsername_available: async (value, attr, key, passes) => {
    const msg = t('Super user already exists.');

    try {
      await request.get(`/ra/members/username/${value}`, {
        params: {
          external: true,
          includePending: true
        }
      });

      passes(false, msg);
    } catch (error) {
      passes();
    }
  },
  phone_available: function(value, attr, key, passes) {
    if (value == '+') return passes(); //nothing to check

    // removing all special chars " ()-+" from the phone number
    value = `+${value.replace(/\D/g, '')}`;

    const msg = t('Phone number is already used, please provide another.');

    request
      .get(
        `/ra/companies/${this.validator.input.companyUuid}/members/validate`,
        {
          params: {
            key: 'phoneNumber',
            value: value,
            excludeUUIDs: this.validator.input.uuid,
            status: 'ACTIVE,INVITED'
          }
        }
      )
      .then(response => {
        passes();
      })
      .catch(error => {
        error.response.status === 409 ? passes(false, msg) : passes();
      });
  },
  employeeId_available: function(value, attr, key, passes) {
    if (!value.length) return passes(); //nothing to check

    const msg = t('Employee Id is already used, please provide another.');

    request
      .get(
        `/ra/companies/${this.validator.input.companyUuid}/members/validate`,
        {
          params: {
            key: 'employeeId',
            value: value,
            excludeUUIDs: this.validator.input.uuid,
            status: 'ACTIVE,INVITED'
          }
        }
      )
      .then(response => {
        passes();
      })
      .catch(error => {
        error.response.status === 409 ? passes(false, msg) : passes();
      });
  },
  crew_name_available: function(value, attr, key, passes) {
    if (!value.length) return passes(); //nothing to check

    const msg = t(
      'A crew with this name already exists. Please enter a new crew name and try again.'
    );

    request
      .get(this.validator.input.url, {
        params: {
          limit: 1,
          name: `${value}`
        }
      })
      .then(response => {
        if (
          response.data.collection[0] &&
          response.data.collection[0].name !== this.validator.input.initialName
        ) {
          passes(false, msg);
        } else {
          passes();
        }
      })
      .catch(error => {
        passes(false, t('Could not load crew name.'));
      });
  },
  template_name_available: async function(value, attr, key, passes) {
    if (!value.length) return passes(); //nothing to check

    const msg = t(
      'A template with this name already exists. Please provide another.'
    );

    const response = await request.get(this.validator.input.templateUrl, {
      params: {
        query: value,
        status: 'ACTIVE,DRAFT',
        scope: 'COMPANY'
      }
    });

    const existingTemplate = response.data.collection.find(
      template => template.name === value
    );

    if (
      existingTemplate &&
      existingTemplate.name !== this.validator.input.initialName
    ) {
      passes(false, msg);
    } else {
      passes();
    }
  },
  form_name_available: async function(value, attr, key, passes) {
    if (!value.length) return passes(); //nothing to check

    const msg = t(
      'A form with this name already exists. Please provide another.'
    );

    const response = await request.get(this.validator.input.formUrl, {
      params: {
        name: `${value}.pdf`
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });

    const existingForm = response.data.collection.find(
      form => form.name.replace(/\.[^/.]+$/, '') === value
    );

    if (
      existingForm &&
      existingForm.name !== this.validator.input.initialName
    ) {
      passes(false, msg);
    } else {
      passes();
    }
  },
  form_template_name_available: async function(value, attr, key, passes) {
    if (!value.length) return passes(); //nothing to check

    const msg = t(
      'A form template with this name already exists. Please provide another.'
    );

    const response = await request.get(this.validator.input.formUrl, {
      params: {
        name: `${value}.pdf`
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });

    const existingForm = response.data.collection.find(
      form => form.name.replace(/\.[^/.]+$/, '') === value
    );

    if (
      existingForm &&
      existingForm.name !== this.validator.input.initialName
    ) {
      passes(false, msg);
    } else {
      passes();
    }
  },
  small_equipment_name_available: function(value, attr, key, passes) {
    if (
      !value.length ||
      (this.validator.input.initialName &&
        value === this.validator.input.initialName)
    )
      return passes(); //nothing to check

    const msg = t(
      'Small equipment name is already used, please provide another.'
    );

    request
      .get(
        `${window.appConfig.tracking_api_url}/companies/${this.validator.input.companyUuid}/smallequipment`,
        {
          params: {
            query: value
          }
        }
      )
      .then(response => {
        if (
          response.data.collection.find(
            smallEquipment => smallEquipment.name === value
          )
        ) {
          passes(false, msg);
        } else {
          passes();
        }
        passes();
      })
      .catch(error => {
        passes(false);
      });
  }
};

export default {
  dvr: {
    package: validatorjs,
    extend: $validator => {
      $validator.useLang(moment.locale());

      // Custom rules.
      Object.keys(customRules).forEach(key =>
        $validator.register(
          key,
          customRules[key].function,
          customRules[key].message
        )
      );

      // Custom implicit rules.
      Object.keys(implicitRules).forEach(key =>
        $validator.registerImplicit(
          key,
          implicitRules[key].function,
          implicitRules[key].message
        )
      );

      // Async rules
      Object.keys(asyncRules).forEach(key =>
        $validator.registerAsyncRule(key, asyncRules[key])
      );

      // Custom messages
      const messages = $validator.getMessages('en');
      messages.not_in = 'This :attribute already exists.';
      messages.between = ':attribute must be between :min and :max.';
      $validator.setMessages('en', messages);
    }
  }
};
