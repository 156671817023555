import request from 'axios';
import { observable, action, computed, reaction } from 'mobx';
import omit from 'lodash.omit';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import UIStore from './UIStore';
import Users from '../collections/Users';
import { getMemberRoles } from 'utils/roles';

import {
  MemberForm,
  memberFormOptions,
  memberFormFields,
  memberFormFieldOptions,
  memberFormLabels,
  memberFormRules,
  memberFormPlugins
} from 'forms/member';

import {
  MembersForm,
  membersFormOptions,
  membersFormFields,
  membersFormRules,
  membersFormPlugins
} from 'forms/members';

import { t } from 'utils/translate';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';
import { callTrack } from 'utils/segmentIntegration';

import { TEAM_MEMBER_ADDED } from 'utils/segmentAnalytics/eventSpec';
import { seatEstimate, seatEstimateTooltip } from 'utils/billing/seatEstimates';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class ProjectDirectoryTeamMembersAddUI extends UIStore {
  @observable memberForm;
  @observable membersForm;
  @observable step;

  @observable phoneCountry;

  // Workers
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.step = 'existing';

    this.memberForm = null;
    this.membersForm = null;

    // Existing member pagination
    this.selectedMembers = observable([]);
    this.loading = true;
    this.sortField = 'firstName';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.groupsFilters = observable([]);

    // Members
    this.paginatedMembers = new Users(null, {
      rootStore: this.rootStore
    });

    this.fetchMembersDebounced = debounce(this.fetchMembers, BASE_DEBOUNCE);

    this.fetchNextPageOfMembers = throttle(
      this.fetchNextPageOfMembers,
      BASE_DEBOUNCE
    );
  }

  @computed get members() {
    return this.paginatedMembers;
  }

  @computed get hasMembers() {
    return this.members.length > 0;
  }

  @action.bound async setStep(step) {
    if (step === 'new') {
      await this.authorization.checkFeatureAccess('InviteCompanyMembers');
    }

    this.step = step;
  }

  @action.bound setup(step = 'existing') {
    this.phoneCountry = this.defaultPhoneCountry;
    this.setStep(step);
    this.setupMemberForm();
    this.setupMembersForm();
    this.setupReactions();
    this.fetchMembers();
    this.groupSelectorUI.setup();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
    this.groupSelectorUI.tearDown();
  }

  setupReactions() {
    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        this.fetchMembers();
      }
    );

    this.cancelReactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchMembersDebounced();
      }
    );

    this.cancelReactToMemberFormRules = reaction(
      () => this.memberFormRules,
      memberFormRules => {
        this.memberForm.set('rules', this.memberFormRules);
        this.memberForm.validate();
      },
      {
        delay: 50
      }
    );
  }

  tearDownReactions() {
    this.cancelReactToParams && this.cancelReactToParams();
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
    this.cancelReactToMemberFormRules && this.cancelReactToMemberFormRules();
  }

  @computed get memberFormRules() {
    return Object.assign({}, memberFormRules, {
      username: `required|email|max:200|username_available|not_in:${this.membersFormUsernames}`
    });
  }

  @action.bound setupMemberForm() {
    this.memberForm = new MemberForm(
      {
        fields: memberFormFields,
        rules: this.memberFormRules,
        labels: memberFormLabels,
        options: memberFormFieldOptions
      },
      {
        options: Object.assign({}, memberFormOptions, {
          validateOnInit: true,
          showErrorsOnInit: false
        }),
        plugins: memberFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound setupMembersForm() {
    this.membersForm = new MembersForm(
      {
        fields: membersFormFields,
        rules: membersFormRules
      },
      {
        options: membersFormOptions,
        plugins: membersFormPlugins
      }
    );
  }

  @action.bound cancelMembersAdd() {
    history.push(`${this.project.viewUrl}/directory/team`);
  }

  @action.bound submitMemberForm(event) {
    event.preventDefault();

    if (this.memberForm.submitting) return;

    this.memberForm.submit({
      onSuccess: this.submitMemberFormSuccess,
      onError: this.submitMemberFormError
    });
  }

  @action.bound submitMemberFormError() {
    console.error(this.memberForm.errors());
  }

  @action.bound submitMemberFormSuccess() {
    const values = this.membersForm.$('members').values();

    values.unshift(omit(this.memberForm.trimmedValues(), 'avatar'));

    this.membersForm.update({
      members: values
    });

    this.setupMemberForm();

    setTimeout(() => {
      this.phoneCountry = '';
      this.phoneCountry = this.defaultPhoneCountry;
    }, 0);
  }

  @computed get roleOptions() {
    return getMemberRoles();
  }

  @computed
  get membersFormUsernames() {
    if (!this.membersForm) return [];

    return this.membersForm
      .$('members')
      .map(member => member.$('username').value)
      .join(',');
  }

  @computed
  get membersFormEmployeeIds() {
    if (!this.membersForm) return [];

    return this.membersForm
      .$('members')
      .map(member => member.$('employeeId').value)
      .join(',');
  }

  @computed get memberFormIsValid() {
    return this.memberForm.check('isValid');
  }

  @computed get hasAddedMembers() {
    return this.membersForm.$('members').size > 0;
  }

  @computed get seatCount() {
    return this.membersForm.$('members').size;
  }

  @computed get seatEstimate() {
    if (!this.subscription || !this.hasAddedMembers) return null;

    return seatEstimate(
      this.subscription,
      this.seatCount,
      this.isRecurlyBilling
    );
  }

  @computed get seatEstimateTooltip() {
    return seatEstimateTooltip(
      this.subscription,
      this.seatCount,
      this.isRecurlyBilling
    );
  }

  @action.bound removeMember(member) {
    this.membersForm.$('members').del(member.key);

    if (!this.membersForm.$('members').size) {
      this.clearValidationDetails();
    }
  }

  @action.bound submitMembersForm(event) {
    event.preventDefault();

    if (this.membersForm.submitting) return;

    this.membersForm.submit({
      onSuccess: this.submitMembersFormSuccess,
      onError: this.submitMembersFormError
    });
  }

  @action.bound submitMembersFormSuccess() {
    // TODO : https://rakenapp.atlassian.net/browse/ENG-11854
    // Turn back on team members defaults once the API supports this.
    // this.setStep('defaults');

    this.createMembers();
  }

  @action.bound skipDefaults() {
    this.membersForm.$('workers').map(member => {
      member.$('defaultShift').clear();
      member.$('defaultCostCode').clear();
      member.$('defaultCrewName').clear();
    });

    this.setStep('projects');
  }

  @action.bound completeDefaults() {
    this.createMembers();
  }

  @action.bound async createMembers() {
    if (this.saving) return;

    this.saving = true;

    try {
      const memberFields = this.membersForm.trimmedValues().members;
      const message = this.membersForm.$('message').value;

      memberFields.forEach(memberField => {
        memberField.company = {
          uuid: this.company.uuid,
          name: this.company.name
        };
        memberField.projectUuids = [this.project.uuid];
        memberField.allowSubscriptionUpgrade = true;
        memberField.message = message;
      });

      const payload = memberFields.map(memberField => {
        const memberFieldData = pickBy(
          memberField,
          value => value !== undefined && value !== '' && value !== null
        );

        memberFieldData.settings = {
          workerDefaultShift: memberField.defaultShift?.uuid,
          workerDefaultCostCode: memberField.defaultCostCode?.uuid,
          workerDefaultCrewName: memberField.defaultCrewName?.uuid
        };

        return omit(memberFieldData, [
          'defaultShift',
          'defaultCostCode',
          'defaultCrewName'
        ]);
      });

      await request.post(`${this.rootStore.users.url()}/batch`, payload);

      this.parent.sortByLastCreated();
      this.parent.refetchSubscription();
      this.cancelMembersAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Team members invited')
      });

      memberFields.forEach(memberField => {
        callTrack(TEAM_MEMBER_ADDED, {
          email: memberField.username,
          first_name: memberField.firstName,
          last_name: memberField.lastName
        });
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitMembersFormError() {
    console.error(this.membersForm.errors());
  }

  @action.bound
  async fetchMembers() {
    this.loading = true;

    await this.members.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound fetchNextPageOfMembers() {
    if (this.members.fetching) return;

    if (this.members.hasNextPage) {
      this.members.fetchNextPage();
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      companyUuids: this.company.uuid,
      role: 'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER',
      excludeProjectUuids: this.project.uuid,
      status: 'ACTIVE,INVITED',
      isPending: 'false',
      groupUuids: this.groupsFilters.map(group => group.uuid).join(',')
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.members.totalElements / this.pageSize);
  }

  @computed get hasSelectedMembers() {
    return this.selectedMembers.length > 0;
  }

  @action.bound
  toggleSelectMember(memberUuid) {
    if (this.selectedMembers.find(uuid => uuid === memberUuid)) {
      this.selectedMembers.remove(memberUuid);
    } else {
      this.selectedMembers.push(memberUuid);
    }
  }

  @computed
  get allMembersSelected() {
    return (
      this.hasMembers &&
      this.members.models.every(member =>
        this.selectedMembers.includes(member.uuid)
      )
    );
  }

  @action.bound
  toggleSelectAllMembers() {
    if (this.allMembersSelected) {
      this.selectedMembers.replace(
        this.selectedMembers.filter(uuid => {
          return !this.members.models.map(member => member.uuid).includes(uuid);
        })
      );
    } else {
      this.members.models.forEach(member => {
        if (!this.selectedMembers.includes(member.uuid)) {
          this.selectedMembers.push(member.uuid);
        }
      });
    }
  }

  @action.bound async addExistingMembersToProject() {
    this.saving = true;

    try {
      await request.post(
        `/ra/projects/${this.project.uuid}/members`,
        this.selectedMembers.slice()
      );

      this.parent.sortByLastUpdated();
      this.cancelMembersAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Team members added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelAdd() {
    this.setStep('existing');
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.memberForm = null;
    this.membersForm = null;
    this.searchQuery = '';
    this.groupsFilters.clear();
    this.selectedMembers.clear();
    this.members.clear();
    this.page = 1;
    this.step = 'new';
    this.loading = true;
  }
}
