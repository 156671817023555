import { action, computed, observable } from 'mobx';

import UIStore from 'stores/ui/UIStore';

import Policy from 'stores/models/time/Policy';

import { t } from 'utils/translate';
import history from 'utils/history';

import PolicySettingsUI from './PolicySettingsUI';
import PolicyOverTimeUI from './PolicyOverTimeUI';
import PolicyBreaksUI from './PolicyBreaksUI';
import PolicyRoundingUI from './PolicyRoundingUI';
import PolicyPayTypesUI from './PolicyPayTypesUI';
import PolicyShiftsUI from './PolicyShiftsUI';
import PolicyEmployeesUI from './PolicyEmployeesUI';

export default class PolicyEditUI extends UIStore {
  @observable entryForEdit;

  constructor(options) {
    super(options);

    this.entryForEdit = null;

    this.policySettingsUI = new PolicySettingsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.policyOverTimeUI = new PolicyOverTimeUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.policyBreaksUI = new PolicyBreaksUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.policyRoundingUI = new PolicyRoundingUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.policyPayTypesUI = new PolicyPayTypesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.policyShiftsUI = new PolicyShiftsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.PolicyEmployeesUI = new PolicyEmployeesUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup(id) {
    this.fetchEntry(id);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.policies.get(uuid);

      if (!model) {
        model = new Policy(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );
      }

      await Promise.all([
        model.fetch(),
        this.policySettingsUI.fetchChecklistTemplates()
      ]);

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelPolicyEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.activeModal = null;
    this.entryForEdit = null;
    this.policySettingsUI.checklistTemplates.clear();
  }

  @action.bound
  cancelPolicyEdit() {
    if (this.entryForEdit.default) {
      history.push({
        pathname: `/company/time/policies`,
        search: this.baseQueryParams
      });
    } else {
      history.push({
        pathname: `/company/time/policies/manage`,
        search: this.baseQueryParams
      });
    }
  }

  @computed get tabs() {
    return [
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/settings`
        },
        label: t('Settings')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/overtime`
        },
        label: t('Overtime rules')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/breaks`
        },
        label: t('Breaks')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/rounding`
        },
        label: t('Rounding')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/pay-types`
        },
        label: t('Pay types')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/shifts`
        },
        label: t('Shifts')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/employees`
        },
        label: t('Employees')
      }
    ];
  }

  @computed get showUI() {
    return this.entryForEdit;
  }

  @computed get setupFirstPolicy() {
    return (
      this.company.preferences.timePolicyStatus === 'INITIAL' &&
      this.entryForEdit.default
    );
  }

  @action.bound stepByStepHeaderButtonText(title) {
    const currentIndex = this.tabs.findIndex(tab => {
      return tab.label === title;
    });

    // If current tab not found or it's the last tab, return null
    if (currentIndex === -1 || currentIndex === this.tabs.length - 1) {
      return t(`Save & finish`);
    }

    return `${t(`Next:`)} ${this.tabs[currentIndex + 1].label}`;
  }
}
