import { observable, action, computed } from 'mobx';
import UIStore from './UIStore';
import Worker from '../models/Worker';

import TeamWorkerInfoUI from './TeamWorkerInfoUI';
import TeamWorkerDefaultsUI from './TeamWorkerDefaultsUI';
import TeamWorkerProjectsUI from './TeamWorkerProjectsUI';
import TeamWorkerCertificationsUI from './TeamWorkerCertificationsUI';

import { t } from 'utils/translate';
import history from 'utils/history';

export default class TeamWorkerProfileUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;
  @observable fetchingEntryForEdit;

  constructor(options) {
    super(options);

    // Editing
    this.entryForEdit = null;
    this.fetchingEntryForEdit = false;

    this.teamWorkerInfoUI = new TeamWorkerInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamWorkerDefaultsUI = new TeamWorkerDefaultsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamWorkerProjectsUI = new TeamWorkerProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamWorkerCertificationsUI = new TeamWorkerCertificationsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup(id) {
    this.findOrFetchWorker(id);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.nextUrl = null;
    this.fetchingEntryForEdit = false;
  }

  @action.bound async findOrFetchWorker(workerUuid) {
    if (this.entryForEdit || this.fetchingEntryForEdit) return;

    let workerModel = this.workers.models.find(
      worker => worker.uuid === workerUuid
    );

    if (workerModel) {
      this.fetchingEntryForEdit = true;

      await workerModel.fetch();

      await this.setEntryForEdit(workerModel);

      this.fetchingEntryForEdit = false;
    } else {
      workerModel = new Worker(
        {
          workerUuid: workerUuid,
          uuid: workerUuid
        },
        {
          rootStore: this.rootStore
        }
      );

      try {
        this.fetchingEntryForEdit = true;

        await workerModel.fetch();

        await this.setEntryForEdit(workerModel);
      } catch (error) {
        this.backToWorkers(true);
      } finally {
        this.fetchingEntryForEdit = false;
      }
    }
  }

  @action.bound async setEntryForEdit(worker) {
    this.entryForEdit = worker;
  }

  @action.bound backToWorkers(replaceHistory = false) {
    if (this.featureFlags.FF_COMPANY_CONTACTS) {
      if (replaceHistory) {
        history.replace('/team');
      } else {
        history.push('/team');
      }
    } else {
      if (replaceHistory) {
        history.replace('/workers');
      } else {
        history.push('/workers');
      }
    }
  }

  @computed get profileLinks() {
    let links = [
      {
        path: `${this.entryForEdit.viewUrl}/info`,
        label: t('Info')
      }
    ];

    if (this.authorization.canEditWorkers) {
      links.push({
        path: `${this.entryForEdit.viewUrl}/attributes`,
        label: t('Default attributes')
      });
    }

    if (this.authorization.canEditWorkerProjects) {
      links.push({
        path: `${this.entryForEdit.viewUrl}/projects`,
        label: t('Projects')
      });
    }

    links.push({
      path: `${this.entryForEdit.viewUrl}/certifications`,
      label: t('Certifications')
    });

    return links;
  }
}
